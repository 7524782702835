import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useDispatch } from 'utils/useDispatch';
import { useI18n } from 'utils/useI18n';
import { atoms } from '@recoil/atoms';
import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { Alert } from '@ui/Alert/Alert';
import { Button } from '@ui/Button/Button';
import { Input, inputClassNames } from '@ui/Form/Input';
import { Icon } from '@ui/Icon/Icon';
import { Modal as MModal } from '@ui/Modal/Modal';


export const Modal = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const setPending = useSetRecoilState(atoms.layout.pending);
  const [isModalOpen, toggleModal] = useRecoilState(atoms.playlists.modal);
  const [playlistUUID, setEditedPlaylistUUID] = useRecoilState(atoms.playlists.editedPlaylistUUID);
  const [isDisabled, toggleDisabled] = useState(false);
  const [fileName, setFileName] = useState(null);
  const makePlaylistSelector = useMemo(playlists.selectors.makePlaylistSelector, []);
  const playlist = useSelector(state => makePlaylistSelector(state, playlistUUID ));
  const settings = useSelector(account.selectors.settings);
  const errorStatus = useSelector(playlists.selectors.errorStatus);
  const errorKey = useSelector(playlists.selectors.errorKey);
  const isEdit = !!playlistUUID;

  const createPlaylist = useDispatch(playlists.actions.createPlaylist);
  const editPlaylist = useDispatch(playlists.actions.editPlaylist);
  const clearModal = useDispatch(playlists.actions.clearModal);

  const providers = useMemo(() => get(settings, 'providers', []), [settings]);

  useEffect(() => {
    return () => {
      setEditedPlaylistUUID(null);
    }
  }, [setEditedPlaylistUUID]);

  const handleCloseModal = useCallback(() => {
    toggleModal(false);
    setEditedPlaylistUUID(null);
    clearModal();
  }, [toggleModal, setEditedPlaylistUUID, clearModal]);

  useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27){
        handleCloseModal();
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [handleCloseModal]);

  const sendRequest = useCallback(async (formData) => {
    if(isEdit) {
      formData.append('_method', 'put');
      return await editPlaylist({ uuid: playlistUUID, data: formData});
    }
    else {
      return await createPlaylist({ data: formData});
    }
  }, [isEdit, playlistUUID, createPlaylist, editPlaylist]);

  const handleSubmit = async ({ name, url, file, archiveId }, { resetForm }) => {
    if(!name) return null;
    toggleDisabled(true);
    setPending(true);

    const formData = new FormData();
    formData.append('name', name);
    if(get(file, 'name')) {
      formData.append('file', file);
    }
    else {
      formData.append('url', url);
    }
    if(archiveId) {
      formData.append('archive_id', archiveId);
    }

    await sendRequest(formData).then((response) => {
      if(response.meta.requestStatus === 'fulfilled') {
        const uuid = response.payload.uuid;
        toggleModal(false);
        navigate(`/app/playlists/${uuid}`, { replace: true });
        resetForm();
      }
      toggleDisabled(false);
      setPending(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: get(playlist, 'name', ''),
      url: get(playlist, 'url', ''),
      file: null,
      archiveId: get(playlist, 'archive_id', null),
    },
    onSubmit: handleSubmit,
  });

  const onDrop = useCallback(([file, ...rest]) => {
    formik.setFieldValue('file', file);
    setFileName(file.name);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const errorMessage = useI18n(errorKey, t(errorStatus === 404 ? 'playlists.createFileError' : 'playlists.createError'));

  const isFile = get(playlist, 'source_type', null);
  if(!isModalOpen) return null;

  return (
    <MModal
      title={t('playlists.addPlaylist')}
      handleCloseModal={handleCloseModal}
      footerButtons={(
        <>
          <Button view="ghost" size="middle" onClick={handleCloseModal}>{t('buttons.close')}</Button>
          <Button view="outline" size="middle" type="submit" form="createPlaylistForm" disabled={isDisabled}>{t('buttons.save')}</Button>
        </>
      )}
    >
      <form
        id="createPlaylistForm"
        onSubmit={formik.handleSubmit}
      >
        <Input
          type="text"
          name="name"
          className="mb-4 w-full"
          value={formik.values.name}
          onChange={formik.handleChange}
          placeholder={t('fields.name')}
        />
        {
          !isFile && (
            <Input
              type="url"
              name="url"
              className="mb-4 w-full"
              value={formik.values.url}
              onChange={formik.handleChange}
              placeholder={t('fields.link')}
            />
          )
        }

        {!isEdit && (
          <div
            style={{ height: '56px' }}
            className={`${inputClassNames} text-gray-500 mb-4 relative py-4 pl-5 pr-10`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the files here ...</p> :
                <p className="truncate flex">
                  {fileName || t('playlists.uploadFile')}
                  <Icon name="clip" className="absolute right-3" />
                </p>
            }
          </div>
        )}
        <div className="relative">
          <select
            className={`appearance-none w-full cursor-pointer ${inputClassNames} py-4 px-5`}
            name="archiveId"
            onChange={formik.handleChange}
            value={formik.values.archiveId}
          >
            <option value="">{t('playlists.selectProvider')}</option>
            {providers.map(provider => <option key={provider.id} value={provider.id}>{provider.name}</option>)}
          </select>
          <Icon name="chevron" className="absolute right-4 top-0 bottom-0 my-auto" />
        </div>
        { !!errorStatus && (
          <Alert type="danger">{errorMessage}</Alert>
        ) }
      </form>
    </MModal>
  );
}
