import React from 'react';

import { SVG } from './SVG';

export const Plus = ({ className, stroke}) => (
  <SVG width={24} className={className}>
    <line x1="12.5" y1="4" x2="12.5" y2="19" stroke={stroke} />
    <line x1="20" y1="11.5" x2="5" y2="11.5" stroke={stroke} />
  </SVG>
);
