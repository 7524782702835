import React from 'react';

export const SVG = ({ className, fill, width, height, viewBox, children }) => (
  <svg
    className={className}
    width={width}
    height={height || width}
    viewBox={viewBox || `0 0 ${width} ${height || width}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);

SVG.defaultProps = {
  fill: 'none',
}

export const Check = ({ className }) => (
  <SVG width={24} className={className}>
    <path d="M7 13L10 16L17 9" stroke="#22D350" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#22D350"/>
  </SVG>
);

export const Loader = ({ className }) => (
  <svg width="306" height="307" viewBox="0 0 306 307" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g filter="url(#filter0_d)">
      <path fillRule="evenodd" clipRule="evenodd" d="M153 273.637C219.826 273.637 274 219.544 274 152.817C274 86.09 219.826 31.997 153 31.997C86.1735 31.997 32 86.09 32 152.817C32 219.544 86.1735 273.637 153 273.637ZM153 275.634C220.931 275.634 276 220.647 276 152.817C276 84.987 220.931 30 153 30C85.069 30 30 84.987 30 152.817C30 220.647 85.069 275.634 153 275.634Z" fill="#FFC815"/>
    </g>
    <path opacity="0.5" d="M207.65 179.149L178.205 128.224C166.658 108.254 160.885 98.269 152.224 98.269C143.564 98.269 137.791 108.254 126.244 128.224L96.7987 179.149C85.2517 199.119 79.4781 209.104 83.8083 216.593C88.1384 224.082 99.6854 224.082 122.779 224.082H181.669C204.763 224.082 216.31 224.082 220.64 216.593C224.97 209.104 219.197 199.119 207.65 179.149Z" fill="#007EFD"/>
    <path opacity="0.5" d="M178.657 98.9652L127.657 128.366C107.657 139.896 97.6572 145.661 97.6572 154.308C97.6572 162.956 107.657 168.721 127.657 180.25L178.657 209.651C198.657 221.181 208.657 226.946 216.157 222.623C223.657 218.299 223.657 206.769 223.657 183.709V124.907C223.657 101.848 223.657 90.3178 216.157 85.9941C208.657 81.6704 198.657 87.4353 178.657 98.9652Z" fill="#5926EB"/>
    <path opacity="0.5" d="M98.3555 128.078L127.8 179.002C139.347 198.973 145.121 208.958 153.781 208.958C162.441 208.958 168.215 198.973 179.762 179.002L209.207 128.078C220.754 108.108 226.527 98.1229 222.197 90.6341C217.867 83.1453 206.32 83.1453 183.226 83.1453L124.336 83.1453C101.242 83.1453 89.6953 83.1453 85.3652 90.6341C81.035 98.123 86.8086 108.108 98.3555 128.078Z" fill="#DC5134"/>
    <path opacity="0.5" d="M126.933 208.279L177.857 178.834C197.827 167.287 207.813 161.514 207.813 152.854C207.813 144.193 197.827 138.42 177.857 126.873L126.933 97.428C106.963 85.881 96.9777 80.1075 89.4888 84.4376C82 88.7678 82 100.315 82 123.409L82 182.298C82 205.392 82 216.939 89.4889 221.27C96.9777 225.6 106.963 219.826 126.933 208.279Z" fill="#FFC815"/>
    <path d="M275.75 152.828C275.75 187.172 257.448 214.088 234.601 236.371C212.449 257.977 186.345 275.405 153 275.405C117.571 275.405 90.5682 255.479 68.131 231.438C47.6677 209.511 30.25 185.082 30.25 152.828C30.25 119.027 49.3921 93.8335 71.625 71.6187C93.8749 49.3867 119.125 30.25 153 30.25C186.346 30.25 212.674 47.4559 234.825 69.0605C257.671 91.342 275.75 118.482 275.75 152.828Z" stroke="#FFC815" strokeWidth="0.5"/>
    <path opacity="0.3" d="M266.017 214.115C248.869 245.737 218.579 261.381 186.419 270.49C155.237 279.322 122.501 282.335 91.7987 265.686C59.1778 247.996 44.2644 216.167 35.6103 182.831C27.7176 152.428 23.8777 121.238 39.9826 91.5401C56.8594 60.4182 87.0639 46.7796 118.623 37.4273C150.207 28.0679 183.011 23.055 214.201 39.9689C244.905 56.6188 260.554 85.6064 270.162 116.556C280.071 148.476 283.166 182.491 266.017 214.115Z" stroke="#FFC815" strokeWidth="0.5"/>
    <g filter="url(#filter1_f)">
      <path d="M168.772 272.419L177.859 271.594C154.787 278.392 149.377 276.985 128.585 273.424C128.585 273.424 127.164 272.315 129.158 272.464C144.578 274.119 147.623 269.333 156.419 269.991C161.526 270.373 163.52 273.35 168.772 272.419Z" fill="#FFC815"/>
    </g>
    <path d="M168.772 272.419L188 268.991C172.5 274 161.5 277.192 157.5 277.5C147 278.308 142.18 275.257 129 273C129 273 127.164 272.315 129.158 272.464C144.578 274.119 147.705 269.333 156.5 269.991C161.608 270.373 163.52 273.351 168.772 272.419Z" fill="#FFC815"/>
    <circle opacity="0.2" cx="153" cy="154" r="152.75" stroke="#FFC815" strokeWidth="0.5"/>
    <defs>
      <filter id="filter0_d" x="23" y="23" width="260" height="259.634" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="3.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.0823529 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_f" x="119.094" y="260.928" width="67.7647" height="24.4672" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="4.5" result="effect1_foregroundBlur"/>
      </filter>
    </defs>
  </svg>
);

export const Battery = ({ className, stroke }) => (
  <SVG className={className} width={24}>
    <path d="M21 14V10" stroke={stroke} strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 6L4 6L4 18L20 18L20 6Z" stroke={stroke} strokeLinecap="round"/>
    <path d="M12 9L14 12H10L12 15" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
);

export const Eye = ({ className, stroke }) => (
  <SVG className={className} width={24}>
    <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z" stroke={stroke} strokeLinecap="round"/>
    <circle cx="12" cy="12" r="3" stroke={stroke} strokeLinecap="round"/>
  </SVG>
);

export const EyeCrossed = ({ className, stroke }) => (
  <SVG className={className} width={24}>
    <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z" stroke={stroke} strokeLinecap="round"/>
    <circle cx="12" cy="12" r="3" stroke={stroke} strokeLinecap="round"/>
    <path d="M3 21L20 4" stroke={stroke} strokeLinecap="round"/>
  </SVG>
);

export const AppleTV = ({ className }) => (
  <SVG className={className} width={82} height={85}>
    <path d="M0.565038 33.8573L0.568333 33.8288V33.8V33.7999V33.7998V33.7997V33.7996V33.7995V33.7994V33.7993V33.7992V33.7991V33.799V33.7989V33.7988V33.7987V33.7986V33.7985V33.7984V33.7984V33.7983V33.7982V33.7981V33.798V33.7979V33.7978V33.7977V33.7976V33.7975V33.7974V33.7973V33.7973V33.7972V33.7971V33.797V33.7969V33.7968V33.7967V33.7966V33.7966V33.7965V33.7964V33.7963V33.7962V33.7961V33.796V33.796V33.7959V33.7958V33.7957V33.7956V33.7955V33.7955V33.7954V33.7953V33.7952V33.7951V33.795V33.795V33.7949V33.7948V33.7947V33.7946V33.7946V33.7945V33.7944V33.7943V33.7943V33.7942V33.7941V33.794V33.7939V33.7939V33.7938V33.7937V33.7936V33.7936V33.7935V33.7934V33.7933V33.7933V33.7932V33.7931V33.793V33.793V33.7929V33.7928V33.7927V33.7927V33.7926V33.7925V33.7925V33.7924V33.7923V33.7922V33.7922V33.7921V33.792V33.792V33.7919V33.7918V33.7918V33.7917V33.7916V33.7916V33.7915V33.7914C1.25458 27.1716 6.86179 22 13.6667 22H47.8333C51.4696 22 54.761 23.4801 57.1491 25.8512C59.52 28.2392 61 31.5305 61 35.1667V69.3333C61 76.608 55.108 82.5 47.8333 82.5H13.6667C10.0305 82.5 6.73932 81.02 4.35127 78.6492C2.45902 76.7399 1.12656 74.2393 0.665017 71.4608C0.565269 70.7613 0.5 70.0531 0.5 69.3333V35.1667C0.5 34.7167 0.516728 34.276 0.565038 33.8573Z" fill="#28292A" stroke="#454647"/>
    <path d="M44.9485 40.191L54.0306 6.29643C54.982 2.74567 58.4993 0.642313 61.861 1.54306L76.7121 5.52241C80.0737 6.42316 82.0682 10.0034 81.1167 13.5541L72.0347 47.4487L44.9485 40.191Z" fill="#141516" stroke="#454647"/>
    <path d="M72.6602 45.1142L63.5782 79.0088C62.6267 82.5596 59.1094 84.663 55.7477 83.7622L40.8966 79.7829C37.535 78.8821 35.5406 75.3019 36.492 71.7511L45.574 37.8565L72.6602 45.1142Z" fill="#141516" stroke="#454647"/>
    <path d="M58.057 33.6094C57.64 35.1653 56.0407 36.0887 54.4848 35.6718C52.9288 35.2548 52.0055 33.6555 52.4224 32.0996C52.8393 30.5436 54.4386 29.6203 55.9946 30.0372C57.5505 30.4541 58.4739 32.0534 58.057 33.6094Z" fill="#141516" stroke="#454647"/>
    <path d="M53.6356 50.1108C53.2187 51.6668 51.6193 52.5901 50.0634 52.1732C48.5075 51.7563 47.5841 50.157 48.001 48.601C48.4179 47.0451 50.0172 46.1217 51.5732 46.5386C53.1291 46.9556 54.0525 48.5549 53.6356 50.1108Z" fill="#141516" stroke="#454647"/>
    <path d="M50.9825 60.0117C50.5656 61.5676 48.9663 62.491 47.4103 62.0741C45.8544 61.6572 44.931 60.0579 45.3479 58.5019C45.7648 56.946 47.3642 56.0226 48.9201 56.4395C50.476 56.8564 51.3994 58.4558 50.9825 60.0117Z" fill="#141516" stroke="#454647"/>
    <path d="M69.6077 36.7046C69.1908 38.2605 67.5915 39.1839 66.0356 38.767C64.4796 38.3501 63.5563 36.7507 63.9732 35.1948C64.3901 33.6388 65.9894 32.7155 67.5453 33.1324C69.1013 33.5493 70.0247 35.1486 69.6077 36.7046Z" fill="#141516" stroke="#454647"/>
    <path d="M58.9613 65.1687C57.4046 64.7516 56.4818 63.1532 56.8989 61.5965L59.5518 51.6958C59.9689 50.1391 61.5673 49.2163 63.124 49.6334C64.6806 50.0505 65.6035 51.6489 65.1864 53.2055L62.5335 63.1063C62.1164 64.6629 60.5179 65.5858 58.9613 65.1687Z" fill="#141516" stroke="#454647"/>
    <path d="M69.2974 10.0924L65.9972 9.20811C65.5417 9.08608 65.2712 8.61741 65.3932 8.16198C65.5152 7.70654 65.9839 7.43596 66.4393 7.55799L69.7396 8.44229C70.195 8.56432 70.4656 9.03299 70.3436 9.48842C70.2215 9.94386 69.7528 10.2144 69.2974 10.0924Z" fill="#454647"/>
  </SVG>
);

export const iPad = ({ className }) => (
  <SVG className={className} width={82} height={91}>
    <path d="M10.0217 76.1433L58.6023 89.1605C60.3031 89.6162 61.9177 88.7354 62.2946 87.3288L80.657 18.7995C81.0339 17.393 80.076 15.8229 78.3752 15.3671L29.7945 2.34999C28.0937 1.89426 26.4791 2.77504 26.1022 4.18161L7.73983 72.7109C7.36294 74.1175 8.32085 75.6876 10.0217 76.1433Z" fill="#28292A" stroke="#454647"/>
    <path d="M72.14 22.8806C70.9947 22.5737 70.3151 21.3966 70.622 20.2514C70.9288 19.1062 72.106 18.4266 73.2512 18.7334C74.3964 19.0403 75.076 20.2174 74.7692 21.3626C74.4623 22.5078 73.2852 23.1874 72.14 22.8806Z" fill="#141516" stroke="#454647"/>
    <path d="M3.75 82.5H56.25C58.1027 82.5 59.5 81.1785 59.5 79.6667V6.33333C59.5 4.82155 58.1027 3.5 56.25 3.5H3.75C1.89727 3.5 0.5 4.82155 0.5 6.33333V79.6667C0.5 81.1785 1.89727 82.5 3.75 82.5Z" fill="#141516" stroke="#454647"/>
  </SVG>
);

export const iPhone = ({ className }) => (
  <SVG className={className} width={66} height={86}>
    <path d="M10.5412 75.9612L43.5062 84.7942C43.972 84.919 44.4957 84.7927 44.9792 84.4156C45.463 84.0383 45.8789 83.4259 46.0843 82.6592L64.4732 14.0311C64.6786 13.2644 64.6246 12.526 64.3943 11.9574C64.1642 11.3891 63.7738 11.0179 63.3079 10.8931L30.343 2.06011C29.8771 1.9353 29.3534 2.06156 28.87 2.43864C28.3862 2.81597 27.9703 3.42838 27.7648 4.1951L9.37598 72.8232C9.17054 73.5899 9.22453 74.3283 9.45482 74.8969C9.68496 75.4652 10.0754 75.8364 10.5412 75.9612Z" fill="#28292A" stroke="#454647"/>
    <path d="M48.6142 16.8647C47.2805 16.5073 46.489 15.1365 46.8464 13.8028C47.2037 12.4691 48.5746 11.6777 49.9082 12.035C51.2419 12.3924 52.0334 13.7632 51.676 15.0969C51.3187 16.4306 49.9478 17.222 48.6142 16.8647Z" fill="#141516" stroke="#454647"/>
    <path d="M46.8024 23.6259C45.4687 23.2685 44.6773 21.8977 45.0346 20.564C45.392 19.2304 46.7628 18.4389 48.0965 18.7963C49.4301 19.1536 50.2216 20.5245 49.8642 21.8581C49.5069 23.1918 48.1361 23.9833 46.8024 23.6259Z" fill="#141516" stroke="#454647"/>
    <path d="M55.3756 18.6762C54.042 18.3188 53.2505 16.948 53.6079 15.6143C53.9652 14.2807 55.3361 13.4892 56.6697 13.8466C58.0034 14.2039 58.7948 15.5748 58.4375 16.9084C58.0801 18.2421 56.7093 19.0335 55.3756 18.6762Z" fill="#141516" stroke="#454647"/>
    <path d="M53.5639 25.4379C52.2302 25.0806 51.4387 23.7097 51.7961 22.3761C52.1534 21.0424 53.5243 20.2509 54.858 20.6083C56.1916 20.9656 56.9831 22.3365 56.6257 23.6701C56.2684 25.0038 54.8975 25.7953 53.5639 25.4379Z" fill="#141516" stroke="#454647"/>
    <path d="M2.5 79.5H37.5C37.9981 79.5 38.4867 79.2323 38.868 78.724C39.2493 78.2155 39.5 77.4899 39.5 76.6667V3.33333C39.5 2.51014 39.2493 1.78454 38.868 1.27604C38.4867 0.767746 37.9981 0.5 37.5 0.5H2.5C2.00192 0.5 1.51325 0.767746 1.13203 1.27604C0.750656 1.78454 0.5 2.51014 0.5 3.33333V76.6667C0.5 77.4899 0.750656 78.2155 1.13203 78.724C1.51325 79.2323 2.00192 79.5 2.5 79.5Z" fill="#141516" stroke="#454647"/>
  </SVG>
);
