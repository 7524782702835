import React from 'react';

import { SVG } from './SVG';

export const Telegram = ({ className }) => (
  <SVG className={className} width={36}>
    <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z" fill="url(#paint0_linear)"/>
    <path d="M14.7 26.25C14.1168 26.25 14.216 26.0298 14.0148 25.4745L12.3 19.8311L25.5 12" fill="#C8DAEA"/>
    <path d="M14.7 26.2501C15.15 26.2501 15.3487 26.0443 15.6 25.8001L18 23.4664L15.0063 21.6611" fill="#A9C9DD"/>
    <path d="M15.0061 21.6614L22.2601 27.0208C23.0879 27.4775 23.6852 27.241 23.8915 26.2523L26.8442 12.3379C27.1465 11.1259 26.3822 10.576 25.5902 10.9355L8.25174 17.6212C7.06824 18.0959 7.07529 18.7562 8.03604 19.0504L12.4855 20.4392L22.7864 13.9405C23.2727 13.6456 23.7191 13.804 23.3528 14.1292" fill="url(#paint1_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="24.012" y1="6.012" x2="15.012" y2="27" gradientUnits="userSpaceOnUse">
        <stop stopColor="#37AEE2"/>
        <stop offset="1" stopColor="#1E96C8"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="20.2567" y1="18.0013" x2="23.0658" y2="24.4017" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EFF7FC"/>
        <stop offset="1" stopColor="white"/>
      </linearGradient>
    </defs>
  </SVG>
)
