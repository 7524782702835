import { useSelector } from 'react-redux';
import { get, isNull } from 'lodash';

import { account } from '@redux/account';

export const IdeasWrapper = () => {
  const settings = useSelector(account.selectors.settings);
  const userHash = get(settings, 'user.hash', null);
  if(isNull(userHash)) return null;
  return (
    <div className="mr-8 md:mr-20" style={{ height: 'calc(100vh - 68px)'}}>
      <iframe
        title="The Beyond Feedback Service for UniPlayer"
        className="w-full h-full"
        src={`https://thebeyond.io/fullscreen/uniplayer/token/${userHash}`}
      />
    </div>
  );
}
