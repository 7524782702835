import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import ru from './ru';
import de from './de';
import es from './es';
import nl from './nl';
import it from './it';
import tr from './tr';
import pl from './pl';
import fr from './fr';
import uk from './uk';
import pt from './pt';

i18n
  .use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    ru: {
      translation: ru,
    },
    de: {
      translation: de,
    },
    es: {
      translation: es,
    },
    nl: {
      translation: nl,
    },
    pl: {
      translation: pl,
    },
    fr: {
      translation: fr,
    },
    uk: {
      translation: uk,
    },
    it: {
      translation: it,
    },
    tr: {
      translation: tr,
    },
    pt: {
      translation: pt,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
