import React from 'react';

import { AddNewDevice } from './components/AddNewDevice';
import { Devices } from './components/Devices';
import { DeviceCounter } from './components/DeviceCounter';

export const DevicesContainer = () => {
  return (
    <div className="flex flex-col lg:flex-row mb-6 mr-6 2xl:mr-20 overflow-hidden" data-test="devices-container">
      <div className="lg:w-2/3 order-2 lg:order-1 grid md:grid-cols-2 gap-2 md:gap-6 xl:gap-8" data-test="devices-list">
        <Devices />
      </div>
      <div className="lg:w-1/3 lg:pl-6 2xl:pl-16 mb-6 order-1 lg:order-2" data-test="devices-info">
        <AddNewDevice className="mb-6" />
        <DeviceCounter />
      </div>
    </div>
  )
}
