import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { first, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'utils/useDispatch';
import { useQuery } from 'utils/useQuery';
import { account } from '@redux/account';
import { Separator } from '@ui/Separator';
import { Link } from '@ui/Link/Link';
import { SignInWithApple } from '@ui/Button/SignInWithApple';
import { Alert } from '@ui/Alert';
import { Input } from '@ui/Form/Input';
import { Button } from '@ui/Button/Button';
import { LanguageDropdown } from '../../components/LanguageDropdown';

export const SignUp = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const getParams = useQuery();
  const location = useLocation();

  const accessToken = useSelector(account.selectors.accessToken);
  const hasError = useSelector(account.selectors.hasError);
  const errors = useSelector(account.selectors.errors);
  const firstError = first(errors);

  const register = useDispatch(account.actions.register);
  const fetchUser = useDispatch(account.actions.fetchUser);
  const setAccessToken = useDispatch(account.actions.setAccessToken);
  const clearErrors = useDispatch(account.actions.clearErrors);

  const handleNavigate = useCallback(() => {
    let { from } = location.state || { from: { pathname: "/app/playlists" } };
    fetchUser();
    navigate(from, { replace: true });
  }, [location.state, navigate, fetchUser]);

  useEffect(() => {
    if(!isEmpty(accessToken)) {
      setAccessToken(accessToken);
      return handleNavigate();
    }
  }, [accessToken, setAccessToken, handleNavigate]);

  useEffect(() => {
    return () => clearErrors();
  }, [clearErrors]);

  return (
    <>
      <header className="flex flex-col space-y-4">
        <div className="flex items-center justify-between mb-8 text-white">
          <h1 className="text-3xl text-center font-bold">{t('auth.signUp')}</h1>
          <LanguageDropdown />
        </div>
        {hasError && <Alert type="danger">{firstError}</Alert>}
        <SignInWithApple />
      </header>
      <Separator className="my-4">{t('common.or')}</Separator>
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          password_confirmation: '',
          query_params: getParams.toString(),
        }}
        onSubmit={register}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              className="w-full"
              type="name"
              name="name"
              placeholder="Name"
              onChange={handleChange}
              value={values.name}
            />
            <Input
              className="w-full"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
            <Input
              className="w-full"
              type="password"
              name="password"
              placeholder={t('fields.password')}
              onChange={handleChange}
              value={values.password}
            />
            <Input
              className="w-full"
              type="password"
              name="password_confirmation"
              placeholder={t('fields.confirmPassword')}
              onChange={handleChange}
              value={values.password_confirmation}
            />
            <Button
              type="submit"
              view="primary"
              disabled={isSubmitting}
              className="font-bold text-base p-2"
              center
              block
              primary
            >{t('auth.signUpButton')}</Button>
          </form>
        )}
      </Formik>
      <Separator className="my-4" />
      <div className="text-gray-500">
        {t('auth.logInTitle')}&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className="text-yellow-400" to="/auth/login">{t('auth.logIn')}</Link>
      </div>
    </>
  );
}
