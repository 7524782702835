import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { useDispatch } from 'utils/useDispatch';
import { useSelector } from 'react-redux';

export const Logout = () => {
  const navigate = useNavigate();
  const language = useSelector(account.selectors.language);

  const logout = useDispatch(account.actions.logout);
  const clearUserState = useDispatch(account.actions.clear);
  const clearPlaylistsState = useDispatch(playlists.actions.clear);
  const setLanguage = useDispatch(account.actions.setLanguage);

  useEffect(() => {
    logout();
    clearUserState();
    clearPlaylistsState();
    navigate('/auth/login');
    setLanguage(language);
  }, [logout, navigate])

  return (<h1>Logout ...</h1>)
}
