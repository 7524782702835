import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { account } from '@redux/account';
import { Input } from '@ui/Form/Input';
import { Button } from '@ui/Button/Button';
import { Alert } from '@ui/Alert';
import { useDispatch } from 'utils/useDispatch';


export const Reset = () => {
  const { t } = useTranslation();
  const hasError = useSelector(account.selectors.hasError);
  const firstError = useSelector(account.selectors.firstError);
  const responseMessage = useSelector(account.selectors.responseMessage);

  const forgotPassword = useDispatch(account.actions.forgotPassword);

  return (
    <>
      <h1 className="mb-8 text-white text-3xl text-center font-bold">{t('auth.resetTitle')}</h1>
      { hasError && <Alert className="mb-4" type="danger">{firstError}</Alert> }
      { responseMessage && <Alert className="mb-4" type="success">{responseMessage}</Alert>}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={values => forgotPassword(values)}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              className="w-full"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
              required
            />

            <Button
              type="submit"
              view="primary"
              disabled={isSubmitting || responseMessage}
              className="font-bold text-base p-2"
              center
              block
            >{t('auth.resetButton')}</Button>
          </form>
        )}
      </Formik>
    </>
  )
}
