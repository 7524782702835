import styled from '@emotion/styled';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { Icon } from './Icon/Icon';

export const Alert = ({ className, type, children }) => (
  <AlertWrapper type={type} className={className}>
    {type === 'danger' && <FaIconWrapper name={faTimesCircle} />}
    {children}
  </AlertWrapper>
);

const AlertWrapper = styled('div')`
  padding: 12px;
  border: 1px solid;
  border-radius: 6px;
  font-size: 14px;
  ${({ type }) => type === 'danger' && `
    color: #DC5134;
    background: rgba(220, 81, 52, 0.1);
    border-color: rgba(220, 81, 52, 0.2);
  `};
  
  ${({ type }) => type === 'success' && `
    background: rgba(34, 211, 80, 0.1);
    border: 1px dashed rgba(34, 211, 80, 0.2);
    color: #22D350;
  `};
`;

const FaIconWrapper = styled(Icon)`
  margin-right: 10px;
`;
