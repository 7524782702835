import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useFormik } from 'formik';

import { account } from '@redux/account';
import { Icon } from '@ui/Icon/Icon';
import { IconButton } from '@ui/Button/IconButton';
import { useDispatch } from 'utils/useDispatch';
import { Input } from '@ui/Form/Input';

export const Device = ({ id, name, token, model, appVersion, createdAt }) => {
  const [isEditMode, setEditMode] = useState(false);
  const language = useSelector(account.selectors.language);
  const editDevice = useDispatch(account.actions.editDevice);
  const deleteDevice = useDispatch(account.actions.deleteDevice);
  const handleDelete = useCallback(() => {
    deleteDevice(token);
  }, [token, deleteDevice]);

  const deviceIconName = useMemo(() => {
    switch (model) {
      case 1:
        return 'appleTV';
      case 2:
        return 'iPhone';
      case 3:
        return 'iPad';
      default:
        return '';
    }
  }, [model]);

  const handleSubmit = useCallback(async (formData, { resetForm }) => {
    if(name === formData.name) {
      return setEditMode(false);
    }
    await editDevice({ id, name: formData.name });
    setEditMode(false);
    resetForm();
  }, [id, name]);

  const handleEditMode = useCallback(() => setEditMode(true), []);
  const handleUndo = useCallback(() => setEditMode(false), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
    },
    validate: values => {
      let errors = {};

      if(!values.name) {
        errors.name = 'Is Required';
      }

      return errors;
    },
    onSubmit: handleSubmit,
  })

  return (
    <div className="w-full flex flex-col shrink relative items-center bg-gray-900 p-6 rounded-lg text-center text-gray-800">
      <IconButton name="times" className="absolute top-4 right-4 text-gray-500 hover:text-white" onClick={handleDelete} />
      <Icon name={deviceIconName} className="mb-8" width={82} />
      <p className="text-white text-lg mb-2">
        {isEditMode ? (
          <form className="flex items-stretch space-x-0.5 xl:space-x-2 relative" onSubmit={formik.handleSubmit}>
            <Input
              autoFocus
              className="w-40 xl:w-auto text-center"
              size="small"
              name="name"
              placeholder="Device name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onClose={handleUndo}
            />
            <span className="absolute flex h-full left-full">
              <IconButton name="check" className="px-1" onClick={formik.handleSubmit} />
              <IconButton name="times" className="px-1 text-red-400" onClick={handleUndo} />
            </span>
          </form>
        ) : (
          <span className="cursor-pointer" onClick={handleEditMode} role="button">{name}</span>
        )}
      </p>
      <p className="text-gray-500 text-sm">
        {DateTime.fromJSDate(new Date(createdAt)).toRelative({ locale: language })}
        {appVersion && ` (v${appVersion})`}
      </p>
    </div>
  );
}
