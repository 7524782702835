import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Basket } from './Basket';
import { Payment } from './Payment';

export const Cart = ({
  currencySign,
  currencyCode,
  order,
  totalPrice,
  totalDays,
  setBasket,
  handleAddToBasket,
  hasWorldwidePayment,
}) => {
  const { t } = useTranslation();

  const handleClearBasket = useCallback(() => setBasket([]), [setBasket]);

  const handleReduceFromBasket = useCallback((planId) => {
    const index = order.findIndex(({ id }) => id === planId);
    setBasket(order.filter((e, i) => i !== index));
  }, [order, setBasket]);

  const handleRemoveGroup = useCallback(planId => setBasket(order.filter(({ id }) => id !== planId)), [order, setBasket]);

  return (
    <>
      <div className="flex border-t border-b border-gray-800 text-gray-700 py-6 mb-6">
        <Basket
          order={order}
          currencySign={currencySign}
          addToBasket={handleAddToBasket}
          reduceFromBasket={handleReduceFromBasket}
          removeGroup={handleRemoveGroup}
        />
      </div>
      <div className="text-white text-xl font-bold flex justify-between mb-6">
        <span>+ {totalDays} {t('plans.days')}</span>
        <span>{currencySign}{totalPrice}</span>
      </div>

      <Payment
        price={totalPrice}
        currencyCode={currencyCode}
        currencySign={currencySign}
        order={order}
        clearBasket={handleClearBasket}
        payPalDisabled={totalPrice < 3}
        hasWorldwidePayment={hasWorldwidePayment}
      />
    </>
  );
}
