import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { playlists } from '@redux/playlists';
import { account } from '@redux/account';

import { CategoryButton } from './CategoryButton';

export const Categories = () => {
  const { t } = useTranslation();
  const playlistCategories = useSelector(playlists.selectors.playlistCategories);
  const isActiveUser = useSelector(account.selectors.isActiveUser);

  if(isEmpty(playlistCategories)) return null;

  return (
    <nav id="playlist-categories" className="w-full md:w-40 h-44 md:h-screen -mt-3 -ml-2.5 pt-3 pl-2.5 md:mr-6 overflow-y-auto flex-none md:sticky md:top-0">
      <h3 className="bg-black text-white uppercase mb-3 md:sticky md:relative top-0">{t('playlists.categories')}</h3>
      <ul className="-ml-2.5">
        {playlistCategories.map(({ id, title }) => <CategoryButton key={id} id={id} title={title} hideActions={!isActiveUser} />)}
      </ul>
    </nav>
  );
}
