import React from 'react';

import { createSVG } from './createSVG';

const Path = () => (
  <>
    <path d="M7 1H19" stroke="#FFC815" strokeLinecap="round"/>
    <path d="M7 6H19" stroke="#FFC815" strokeLinecap="round"/>
    <path d="M3 4L1 6L3 8" stroke="#FFC815" strokeLinecap="round"/>
    <path d="M7 11H19" stroke="#FFC815" strokeLinecap="round"/>
  </>
);

export const Collapse = createSVG(Path, 20, 12);