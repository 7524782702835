import React, { useMemo } from 'react';
import { groupBy, map } from 'lodash';

import { IconButton } from '@ui/Button/IconButton';

export const Basket = ({ order, addToBasket, removeGroup, reduceFromBasket, currencySign }) => {
  const groupedOrders = useMemo(() => map(groupBy(order, 'id'), group => ({...group[0], count: group.length})), [order]);
  return (
    <ul className="w-full space-y-4">
      {
        groupedOrders.map(({ id, name, count, priceUSD}) => (
          <li key={id} className="flex items-center">
            <IconButton name="times" className="mr-3" onClick={() => removeGroup(id)} />
            <span className="relative flex flex-auto">
              <span className="text-white">{name}</span>
              <span className="flex-auto w-full absolute text-center space-x-3">
                <span className="inline-flex items-center">
                  <IconButton name="minus" onClick={() => reduceFromBasket(id)} className="hover:text-white" />
                  <span className="text-white w-8">{count}</span>
                  <IconButton name="plus" onClick={() => addToBasket(id)} className="hover:text-white" />
                </span>
              </span>
              <span className="ml-auto text-white">{currencySign}{priceUSD}</span>
            </span>
          </li>
        ))
      }
    </ul>
  );
}
