import React from 'react';
import classNames from 'classnames';

export const Alert = ({ type, children}) => {
  const types = {
    danger: 'text-red-900',
    success: '',
  };

  return (
    <div className={classNames(
      types[type],
      'rounded p-3'
    )}>{children}</div>
  );
}

Alert.defaultProps = {
  type: 'success',
}