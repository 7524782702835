import React from 'react';

import { SVG } from './SVG';

export const Move = ({ className, stroke }) => (
  <SVG className={className} width={24} >
    <path fillRule="evenodd" clipRule="evenodd" d="M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12Z" stroke={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 12C13 12.5523 13.4477 13 14 13C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12Z" stroke={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6Z" stroke={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 6C13 6.55228 13.4477 7 14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5C13.4477 5 13 5.44772 13 6Z" stroke={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 18C8 18.5523 8.44772 19 9 19C9.55228 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18Z" stroke={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 18C13 18.5523 13.4477 19 14 19C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17C13.4477 17 13 17.4477 13 18Z" stroke={stroke} />
  </SVG>
)
