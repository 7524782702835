import React from 'react';

import { Loader } from './Icon/svg/SVG';

export const Overlay = ({ className }) => {
  return (
    <div className={`w-full h-full bg-black bg-opacity-50 z-100 ${className}`}>
      <Loader className="absolute inset-0 m-auto" />
    </div>
  );
}
