import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { toArray, keyBy } from 'lodash';

import { playlists } from '@redux/playlists';
import { useDispatch } from 'utils/useDispatch';

import { ChannelCardUI } from './components/ChannelCardUI';

const SortableList = ({ channels }) => {
  const initialList = useMemo(() => channels.map(channel => ({ ...channel, chosen: true })), [channels]);
  const [list, setList] = useState(initialList);
  const [isLoading, setLoading] = useState(false);

  const playlistsObject = useSelector(playlists.selectors.playlists);
  const playlistsById = keyBy(toArray(playlistsObject), 'id');

  const reorderFavorites = useDispatch(playlists.actions.reorderFavorites);
  const updateFavorite = useDispatch(playlists.actions.updateFavorite);

  useEffect(() => {
    setList(initialList);
  }, [initialList, setList]);

  const handleOnEnd = useCallback(async ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      setLoading(true);
      await reorderFavorites({ oldIndex, newIndex })
      setLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading && <div className="absolute w-full h-full bg-black opacity-90 cursor-wait top-0 left-0 z-10" />}
      <ReactSortable
        list={list}
        setList={setList}
        className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 5xl:grid-cols-8 auto-rows-fr gap-2"
        onEnd={handleOnEnd}
      >
        {list.map(({ playlist_id, uuid, name, favorite, thumbnail }) => (
          <div key={uuid}>
            <ChannelCardUI
              className="cursor-move"
              isEdit
              key={uuid}
              title={name}
              subtitle={playlistsById[playlist_id]?.name || 'Undefined'}
              thumbnail={thumbnail}
              isFavorite={favorite}
              handleUpdateFavorite={() => updateFavorite({ playlistUUID: playlistsById[playlist_id].uuid, channelUUID: uuid, favStatus: !favorite })}
            />
          </div>
        ))}
      </ReactSortable>
    </>
  );
};


export const Favorites = () => {
  const favorites = useSelector(playlists.selectors.favorites);

  const fetchFavorites = useDispatch(playlists.actions.fetchFavorites);

  useEffect(() => {
    fetchFavorites();
  }, []);

  if (!favorites) return null;

  return (<SortableList channels={favorites} />)
}
