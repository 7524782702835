import { isEmpty } from 'lodash';
import React from 'react';

export const Separator = ({ className, children }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <span className="border-gray-800 border flex-auto" />
      {!isEmpty(children) && <span className="mx-4 text-gray-500">{children}</span>}
      <span className="border-gray-800 border flex-auto" />
    </div>
  );
};

