import { useSelector } from 'react-redux';

import { account } from '@redux/account';

export const useAuth = () => {
  const user = useSelector(account.selectors.user);
  const accessToken = useSelector(account.selectors.accessToken);
  const isAuthenticated = useSelector(account.selectors.isAuthenticated);
  const isAdmin = useSelector(account.selectors.isAdmin);

  return {
    user,
    accessToken,
    isAuthenticated,
    isAdmin,
  }
}
