import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { SizeContext } from '@ui/context/SizeProvider';
import { atoms } from '@recoil/atoms';

export const SidebarLink = ({ to, href, children, isCollapsed }) => {
  const setCollapsed = useSetRecoilState(atoms.layout.sidebarCollapse);
  const { isMobile } = useContext(SizeContext);

  const handleClick = useCallback(() => {
    if(isMobile) setCollapsed(true);
  }, [setCollapsed, isMobile]);

  const cssClasses = classNames(
    'flex w-full items-center font-normal py-3 text-sm space-x-2.5 border-l-2 whitespace-nowrap justify-center',
    {'pl-6 pr-8 md:justify-start' : !isCollapsed }
  );

  if(href) return (
    <a className={cssClasses} href={href} target="_blank">
      {children}
    </a>
  )
  return (
    <NavLink
      className={({ isActive }) => classNames(cssClasses, { 'text-white border-yellow-400': isActive })}
      to={to}
      onClick={handleClick}
    >{children}
    </NavLink>
  );
}
