import classNames from 'classnames';
import React, { useEffect } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { throttle } from 'lodash';

const sizes = {
  small: 'py-2.5 px-4 text-sm',
  default: 'py-4 px-5',
  empty: '',
}

export const inputClassNames = 'bg-gray-900 text-white border border-gray-800 rounded-md focus:border-white focus:outline-none disabled:opacity-75 disabled:cursor-not-allowed';
export const Input = ({ className, size, label, onClose, id = nanoid(), ...rest }) => {

  const handleClose = ({ keyCode }) => {
    if(keyCode === 27) {
      onClose && onClose();
    }
  };

  const throttledClose = throttle(handleClose, 500);

  useEffect(() => {
    window.addEventListener('keydown', throttledClose)
    return () => window.removeEventListener('keydown', throttledClose)
  }, [throttledClose]);

  return (
    <>
      {label && <label htmlFor={id} className="mb-2">{label}</label>}
      <input id={id} className={classNames(inputClassNames, sizes[size], className)}{...rest} />
    </>
  )
};

Input.defaultProps = {
  size: 'default',
};
