import React from 'react';

import { SVG } from './SVG';

export const iPhone = ({ className, stroke }) => (
  <SVG className={className} width={24}>
    <path fillRule="evenodd" clipRule="evenodd" d="M18 3V21C18 21.5523 17.5523 22 17 22H7C6.44772 22 6 21.5523 6 21V3C6 2.44772 6.44772 2 7 2H17C17.5523 2 18 2.44772 18 3V3Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="14" y="2" width="1" height="4" transform="rotate(90 14 2)" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
)
