import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { atoms } from '@recoil/atoms';
import { Icon } from '@ui/Icon/Icon';
import { SizeContext } from '@ui/context/SizeProvider';
import logo from 'assets/logo.svg';

import { CollapseButton } from './components/CollapseButton';
import { SidebarLink } from './components/SidebarLink';

export const Aside = () => {
  const [isCollapsed, setCollapsed] = useRecoilState(atoms.layout.sidebarCollapse);
  const { isMobile } = useContext(SizeContext);

  const sidebarCollapsed = localStorage.getItem('sidebarCollapsed');
  useEffect(() => {
    if(isMobile) setCollapsed(true);
    if (sidebarCollapsed === '1') setCollapsed(true);
  }, [isMobile, sidebarCollapsed, setCollapsed]);

  const { t } = useTranslation();
  return (
    <aside
      className={
        classNames(
          {
            'py-4 md:w-aside-collapsed hidden md:block': isCollapsed,
            'pt-4 md:w-50 pb-8 w-full': !isCollapsed
          },
          'h-full',
          'fixed',
          'top-0',
          'transition-all',
          'bg-gray-900',
          'text-gray-500',
          'flex-none',
          'z-20'
        )}
    >
      <div className="flex flex-col h-full transition-all overflow-hidden">
        <CollapseButton className={`ml-auto mb-7 ${isCollapsed ? 'mr-auto': 'mr-4'}`} />
        <img
          className={
            classNames(
              'mb-14 border-l mx-auto',
              {'md:ml-8': !isCollapsed, 'mx-auto': isCollapsed}
            )}
          src={logo}
          alt=""
          width={isCollapsed ? 42 : 82}
        />
        <nav className="menu">
          <ul>
            <li className="flex">
              <SidebarLink to="/app/playlists" isCollapsed={isCollapsed}>
                <Icon className="shrink-0" name="tv" />
                {!isCollapsed && <span>{t('navBar.playlists')}</span>}
              </SidebarLink>
            </li>
            <li className="flex">
              <SidebarLink to="/app/plans" isCollapsed={isCollapsed}>
                <Icon className="shrink-0" name="battery" />
                {!isCollapsed && <span>{t('navBar.plans')}</span>}
              </SidebarLink>
            </li>
            <li className="flex">
              <SidebarLink to="/app/devices" isCollapsed={isCollapsed}>
                <Icon className="shrink-0" name="devices" />
                {!isCollapsed && <span>{t('navBar.devices')}</span>}
              </SidebarLink>
            </li>
            <li className="flex">
              <SidebarLink to="/app/ideas" isCollapsed={isCollapsed}>
                <Icon className="shrink-0" name="feather" />
                {!isCollapsed && <span>{t('navBar.ideas')}</span>}
              </SidebarLink>
            </li>
            <li className="flex">
              <SidebarLink
                href="https://www.notion.so/esttq/Support-FAQ-e26f5af48dde4a09b03b2eba50d7da1a"
                isCollapsed={isCollapsed}
              >
                <Icon className="shrink-0" name="question" />
                {!isCollapsed && <span>{t('navBar.faq')}</span>}
              </SidebarLink>
            </li>
          </ul>
        </nav>
        <a
          href="https://t.me/UniPlayer"
          target="_blank"
          className={
            classNames(
              'mt-auto inline-flex items-center justify-center',
              {'md:ml-8 md:justify-start': !isCollapsed, 'md:justify-center': isCollapsed}
            )
          }
        >
          <Icon name="telegram" width={36} />
          {
            !isCollapsed && (
              <span className="flex flex-col ml-2 text-xs">
                <span className="font-bold text-yellow-400">@UniPlayer</span>
                <span>Join Us</span>
              </span>
            )
          }
        </a>
      </div>
    </aside>
  );
}
