import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({ to, asButton, isActive, children, href, bold, onClick, ...rest }) => {

  if(href) {
    return <a href={href} rel="noopener noreferrer" {...rest}>{children}</a>
  }
  return <RouterLink to={to} children={children} {...rest} />;
};
