import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';

export const Icon = memo(({ name, size }) => (
  <I className={`fa ${name}`} size={size} />
));

export const FaIcon = memo(({ icon, ...rest }) => {
  if (!icon) return null;
  return <FontAwesomeIcon icon={icon} {...rest} />;
});

const I = styled('i')`
  font-size: ${({ size }) => (size ? `${size}px` : '')};
`;

Icon.defaultProps = {};
