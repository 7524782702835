import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { account } from '@redux/account';
import './index.css';

export const DeviceCounter = () => {
  const { t } = useTranslation();
  const devices = useSelector(account.selectors.devices);
  const settings = useSelector(account.selectors.settings);
  const maxDevices = get(settings, 'user.max_devices', 4);
  const count = devices.length;
  const hasDevices = count > 0;
  return (
    <div className="bg-gray-900 rounded-lg p-5 text-center text-gray-700">
      <div className="image-carousel__index text-5xl font-bold leading-normal">
        <span className={`image-carousel__index__current ${hasDevices && "is-transitioning-forward"}`}>
          <span className="image-carousel__index__current--visible">0</span>
          <span className="image-carousel__index__current--next">{count}</span>
        </span>/{maxDevices}
      </div>
      <p className="text-sm">{t('devices.devicesActivated')}</p>
    </div>
  );
}
