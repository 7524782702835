import axios from 'axios';
import { get } from 'lodash';

import { account } from '@redux/account';
import { history } from './App';
import store from './store';

export let domain;
let epgDomain;

switch(process.env.REACT_APP_ENV) {
  case 'production':
    domain = 'https://core.uniplayer.net';
    epgDomain = 'https://epg.uniplayer.net';
    break;
  case 'staging':
    domain = 'https://core.uniplayer.dev';
    epgDomain = 'https://epg.uniplayer.net';
    break;
  default:
    domain = 'https://core.test';
    epgDomain = 'https://epg.uniplayer.net';
    break;
}

export const api = axios.create({
  baseURL:  `${domain}/api/v1`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiEPG = axios.create({
  baseURL: `${epgDomain}/api`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

[api, apiEPG].map((api) => {
  api.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const token = account.selectors.accessToken(state);
      const lang = account.selectors.language(state);

      config.headers.common.Authorization = token ? `Bearer ${token}` : 'Basic';
      config.headers.lang = lang;
      config.headers.FromSite = 1;
      return config;
    }
  );

  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(get(response, 'config.unwrap') ? response.data : response.data.data || response.data || response);
    }, (error) => {
      const { response } = error;
      if (response.status === 401) {
        history.replace('/auth/logout');
        window.location.reload();
        return Promise.reject();
      }
      return Promise.reject(response);
    }
  );
  return api;
});
