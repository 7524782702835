import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../../@ui/Icon/Icon';
import { Button } from '../../../@ui/Button/Button';
import { useRecoilState } from 'recoil';
import { atoms } from '../../../@recoil/atoms';

export const CollapseButton = ({ className }) => {
  const [isCollapsed, setCollapse] = useRecoilState(atoms.layout.sidebarCollapse);

  const handleClick = () => {
    localStorage.setItem('sidebarCollapsed', isCollapsed ? '0' : '1');
    setCollapse(!isCollapsed);
  }

  return (
    <Button
      className={
        classNames(
          'transition-all',
          {'rotate-180' : isCollapsed},
          className,
        )
      }
      onClick={handleClick}
    >
      <Icon name="uiCollapse" />
    </Button>
  );
}
