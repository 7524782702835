import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { Icon } from '@ui/Icon/Icon';
import { Button } from '@ui/Button/Button';

import { PlaylistCard } from './PlaylistCard';
import { PlaylistCardUI } from './PlaylistCardUI';

export const PlaylistCards = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toggleModalShown = useSetRecoilState(atoms.playlists.modal);

  const includedDays = useSelector(account.selectors.includedDays);
  const keys = useSelector(playlists.selectors.keys);
  const favorites = useSelector(playlists.selectors.favorites);

  const handleFavoritesClick = () => {
    navigate('/app/playlists/favorites');
  }

  return (
    <ul className="flex pl-10 pb-5 space-x-2" style={{ height: '92px' }}>
      <li>
        {includedDays ?
          <Button
            className="bg-gray-900 rounded-lg h-full px-7 text-yellow-400"
            onClick={() => toggleModalShown(true)}
          >
            <Icon name="uiPlus" />
          </Button> :
          <Link
            to="/app/plans"
            className="inline-flex items-center bg-gray-900 rounded-lg h-full px-7 text-yellow-400"
          ><Icon name="battery" /></Link>
        }
      </li>
      <li>
        <PlaylistCardUI
          title="Favorites"
          subtitle={`${favorites.length} channels`}
          hideActions
          className="py-3 items-center"
          onClick={handleFavoritesClick}
          isActive={params['*'] === 'favorites'}
          icon={
            <Icon
              name="star"
              className="mr-4"
              fill="transparent"
              stroke="#FFC815"
              width={46}
            />
          }
        />
      </li>
      {keys.map(uuid => <li key={uuid}><PlaylistCard uuid={uuid} /></li>)}
    </ul>
  );
}
