import React from 'react';

import { SVG } from './SVG';

export const TV = ({ className, stroke }) => (
  <SVG className={className} width={24}>
    <path d="M20 8L20 20L4 20L4 8L20 8Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 4.08325L12 7.99992L16 4.08325" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
)
