import React from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';
// import 'rc-dropdown/assets/index.css';
import styled from '@emotion/styled';

export const DDropdown  = ({ menu, className, children }) => {
  const overlay = menu && (
    <Menu>
      {menu.map(({ title, fn }, i) => (
        <MenuItem
          key={i}
          className="text-white hover:text-yellow-400 cursor-pointer py-2 px-3"
          onClick={fn}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
  )
  return (
    <Dropdown
      overlayClassName={`bg-gray-900 rounded-md shadow-lg ${className}`}
      trigger={['click']}
      animation="slide-up"
      overlay={overlay}
    >
      {children}
    </Dropdown>
  )
}

const Item = styled(MenuItem)`
  //color: white;
`;
