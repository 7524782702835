import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isNull } from 'lodash';

import { account } from '@redux/account';
import { Input } from '@ui/Form/Input';
import { Button } from '@ui/Button/Button';
import { useDispatch } from 'utils/useDispatch';

export const AddNewDevice = ({ className }) => {
  const { t } = useTranslation();
  const isCodeSet = useSelector(account.selectors.isCodeSet);
  const setTVCode = useDispatch(account.actions.setTVCode);
  const clearNotifications = useDispatch(account.actions.clearNotifications);

  const handleSubmit = useCallback(({ code }, { resetForm }) => {
    setTVCode({ code });
    resetForm();
  }, [setTVCode]);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className={className}>
      <h2 className="text-lg text-white mb-4">{t('devices.title')}</h2>
      <p className="mb-2">{t('devices.tvCode')}</p>
      {
        isNull(isCodeSet) ?
          <form className="flex lg:flex-col xl:flex-row" onSubmit={formik.handleSubmit}>
            <Input
              className="lg:flex-auto pl-5 pr-0 w-1/2 lg:w-auto text-center mr-4 lg:mr-0 xl:mr-4 lg:mb-4 xl:mb-0"
              style={{ letterSpacing: '22px' }}
              name="code"
              placeholder="XXXX"
              value={formik.values.code}
              onChange={(e) => {
                if(e.target.value.length > 4) e.target.value = e.target.value.substring(0, 4);
                formik.handleChange(e);
              }}
            />
            <Button className="flex-auto" view="secondary" size="middle" type="submit">{t('devices.addDevice')}</Button>
          </form> :
          <p
            className="flex items-center"
            onClick={clearNotifications}
          >{t(`devices.${isCodeSet ? 'tvCodeActivated' : 'tvCodeWasWrong'}`)}</p>
      }
    </div>
  );
}
