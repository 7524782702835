import React, { memo, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useMatch } from 'react-router-dom';
import { isEmpty, isNull, first, get } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'utils/useDispatch';
import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { Overlay } from '@ui/Overlay';
import { IconButton } from '@ui/Button/IconButton';
import { Link } from '@ui/Link/Link';
import nyPromo from 'assets/ny2024.jpeg';

import { PlaylistCards } from './components/PlaylistCards';
import { Modal } from './Modal';
import { Playlist } from './Playlist';
import { Favorites } from './Favorites';

export const PlaylistsContainer = memo(({ routes }) => {
  const { t } = useTranslation();
  const [isPromoShown, showPromo] = useState(null); // false, 1: BF, 2: NY
  const isPending = useRecoilValue(atoms.layout.pending);
  const navigate = useNavigate();

  const settings = useSelector(account.selectors.settings);
  const playlistsKeys = useSelector(playlists.selectors.keys);

  const fetchPlaylists = useDispatch(playlists.actions.fetchPlaylists);
  const fetchFavorites = useDispatch(playlists.actions.fetchFavorites);

  useEffect(() => {
    if (get(settings, 'promo')) {
      showPromo(settings.promo);
    }
  }, [settings]);

  useEffect(() => {
    //todo: if isLogged In and isEmpty playlists
    fetchPlaylists();
    fetchFavorites();
  }, []);

  let match = useMatch({
    path: '/app/playlists',
    exact: true,
  });

  useEffect(() => {
      if(!isEmpty(playlistsKeys) && !isNull(match)) {
      navigate(`/app/playlists/${first(playlistsKeys)}`);
    }
  }, [match, navigate, playlistsKeys]);

  return (
    <>
      <Modal />

      {isPromoShown > 0 && (
        <div className={`${isPromoShown === 1 ? 'black-friday' : ''} relative text-white text-center md:p-10 mb-5 rounded-xl mr-6`}>
          {
            isPromoShown === 1 && (
              <>
                <h1 className="text-5xl font-bold uppercase mb-2">{t("black_friday.title")}</h1>
                <p className="mb-5 font-bold">{t("black_friday.offer")}</p>
                <Link className="inline-flex items-center rounded-md capitalize gradient py-4 px-8 font-medium text-base"
                      to="/app/plans">{t("black_friday.action")}</Link>
              </>
            )
          }
          {
            isPromoShown === 2 && (
              <Link to="/app/plans">
                <img src={nyPromo} alt=""/>
              </Link>
            )
          }
          <IconButton name="times" className="absolute top-2 md:top-4 right-2 md:right-4" onClick={() => showPromo(0)} />
        </div>
      )}

      <nav className="overflow-x-auto overflow-y-hidden -ml-10 mb-5">
        <PlaylistCards />
      </nav>

      <Routes>
        <Route path="/favorites" element={<Favorites />} />
        <Route path=":playlistUUID" element={<Playlist />} />
      </Routes>

      {isPending && <Overlay className="absolute top-0 left-0" />}
    </>
  )
})
