import React from 'react';

import { Icon } from '@ui/Icon/Icon';
import { Link } from '@ui/Link/Link';
import { domain } from 'api';
import { useTranslation } from 'react-i18next';

export const SignInWithApple = () => {
  const { t } = useTranslation();
  return (
    <Link href={`${domain}/apple/login`} className="bg-white rounded-md p-2 font-medium flex items-center justify-center">
      <Icon name="apple" className="mr-4" /> {t('auth.logInWith')} Apple
    </Link>
  );
}
