import React from 'react';

export const createSVG = (Path, width, height) => ({ className, fill, stroke }) => (
  <svg
    className={className}
    width={width}
    height={height || width}
    viewBox={`0 0 ${width} ${height || width}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path fill={fill} stroke={stroke} />
  </svg>
);
