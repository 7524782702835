import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button } from '@ui/Button/Button';

export const Plan = ({ title,  price, isActive, isYear, promoType, onClick, currencySign }) => {
  const { t } = useTranslation();
  const isPromo = promoType > 0;

  return (
    <>
      <div className={classNames(
        'bg-gray-900 px-5 lg:px-12 py-4 lg:py-10',
        { 'border border-red-400 rounded-t-lg': isPromo },
        { 'rounded-lg' : !isPromo }
      )}>
        <header
          className={classNames(
            'pb-6 border-b border-gray-800 text-xl font-bold text-center md:text-left',
            { 'text-yellow-400': isActive && !isPromo },
            { 'text-red-400': isPromo },
            { 'text-white': !isActive && !isPromo },
          )}
        >{title}</header>
        <div className="py-6 border-b border-gray-800">
          <div className={
            classNames(
              'flex text-2xl space-x-2.5 justify-center md:justify-start',
              { 'text-yellow-400': isActive && !isPromo },
              { 'text-red-400': isPromo },
              { 'text-white': !isActive && !isPromo },

            )
          }>
            <span>{currencySign}</span>
            <span className="text-8xl font-medium -my-2">{price}</span>
            <span className="text-xl self-end">/{t(isYear ? (isPromo ? (promoType === 1 ? 'plans.15mons' : 'plans.14mons') : 'plans.year') : 'plans.month')}</span>
          </div>
        </div>
        <footer className="pt-6">
          <Button className="w-full p-2.5 font-medium" view={isPromo ? 'promo' : 'outline'} icon="plus" onClick={onClick}>{t('buttons.addToBasket')}</Button>
        </footer>
      </div>
      {isPromo && (
        <div className="bg-red-400 text-black text-center font-medium p-2.5 rounded-b-lg">{promoType === 1 ? t('plans.bfSaleText') : t('plans.nySaleText')}</div>
      )}
    </>
  );
};
