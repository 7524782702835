import React from 'react';

import { SVG } from './SVG';

export const Star = ({ className, fill, stroke, width = '20' }) => (
  <SVG
    className={className}
    width={width}
    viewBox="0 0 20 20"
  >
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M10 15.8441L4.1833 18.9021L5.2942 12.4251L0.588351 7.838L7.09167 6.89301L10 1L12.9084 6.89301L19.4117 7.838L14.7059 12.4251L15.8168 18.9021L10 15.8441Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
);
