import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { account } from '@redux/account';
import { DDropdown } from '@ui/Dropdown';
import { Icon } from '@ui/Icon/Icon';
import { LanguageDropdown } from 'components/LanguageDropdown';

import { CollapseButton } from './components/CollapseButton';

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const includedDays = useSelector(account.selectors.includedDays);
  const user = useSelector(account.selectors.user);

  const menu = [
    {
      title: (<span className="text-yellow-400">{user?.email}</span>),
      fn: () => {}
    },
    {
      title: t('navBar.settings'),
      fn: () => navigate('/app/settings'),
    },
    {
      title: t('auth.logout'),
      fn: () => navigate('/auth/logout'),
    }];

  return (
    <header className="flex bg-gray-900 px-6 py-3 text-xs items-center">
      <CollapseButton className="mr-4 md:hidden" />
      <Icon name="brandWave" className="mr-2" />
      <span className="hidden md:inline">{
        includedDays ? <Trans i18nKey="navBar.expirationPeriod">{{ includedDays }}</Trans> : (
          <>
            {t('navBar.subscriptionExpired')}&nbsp;&nbsp;
            {location.pathname !== '/app/plans' && (
              <Link className="font-bold text-yellow-400" to="/app/plans">{t('navBar.goToPlans')}</Link>
            )}
          </>
        )
      }</span>
      <div className="flex items-center ml-auto">
        <LanguageDropdown className="ml-4 md:mr-8" />

        {user && (
          <DDropdown menu={menu}>
            <span className="cursor-pointer inline-flex items-center ml-4 md:mr-8">{user?.name}<Icon name="chevron-xs" className="ml-1" /></span>
          </DDropdown>
        )}
      </div>
    </header>
  );
}
