import React, {useEffect} from 'react';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Aside } from './Aside';
import { Header } from './Header';
import { useRecoilValue } from 'recoil';

import { atoms } from '@recoil/atoms';
import { account } from '@redux/account';

import { SizeProvider } from '@ui/context/SizeProvider';
import { useDispatch } from 'utils/useDispatch';
import { useAuth } from 'utils/useauth';
import { Settings } from '../Settings/Settings';
import { IdeasWrapper } from '../Ideas/IdeasWrapper';
import { DevicesContainer } from '../Devices/Container';
import { PlansContainer } from '../Plans/Container';
import { PlaylistsContainer } from '../Playlists/Container';

export const Layout = () => {
  const auth = useAuth();
  const location = useLocation();

  const isCollapsed = useRecoilValue(atoms.layout.sidebarCollapse);
  const isAuthenticated = useSelector(account.selectors.isAuthenticated);
  const settings = useSelector(account.selectors.settings);
  const fetchSettings = useDispatch(account.actions.fetchSettings);
  const fetchUser = useDispatch(account.actions.fetchUser);
  const language = useSelector(account.selectors.language);

  useEffect(() => {
    if(!settings && isAuthenticated) {
      fetchUser();
    }
  }, [settings, isAuthenticated, fetchSettings, fetchUser]);

  useEffect(() => {
    fetchSettings();
  }, [language]);


  if(!auth.isAuthenticated) return (
    <Navigate
      to="/auth/login"
      state={{ from: location }}
    />
  );

  return (
    <div className={classNames('layout flex', { 'overflow-hidden md:overflow-visible': !isCollapsed})}>
      <SizeProvider>
        <Aside />
      </SizeProvider>
      <div
        className={
          classNames(
            'flex flex-col flex-auto bg-black text-gray-500 w-screen h-screen transition-all',
            {
              'md:pl-aside-collapsed': isCollapsed,
              'md:pl-50': !isCollapsed,
            }
          )
        }
      >
        <Header />
        <main id="content" className="flex-auto ml-6 mt-6">
          <Routes>
            <Route path="playlists/*" element={<PlaylistsContainer />} />
            <Route path="plans" element={<PlansContainer />} />
            <Route path="devices" element={<DevicesContainer />} />
            <Route path="ideas" element={<IdeasWrapper />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </main>
      </div>
    </div>
  )
}
