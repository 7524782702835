import classNames from 'classnames';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { slice, isNumber, isEmpty, includes, first } from 'lodash';
import { ReactSortable } from "react-sortablejs";

import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { Button } from '@ui/Button/Button';
import { useDispatch } from 'utils/useDispatch';
import { ChannelCard } from './components/ChannelCard';
import { Categories } from './components/Categories';
import { account } from '../../@redux/account';

const SortableList = ({ channels }) => {
  const { playlistUUID } = useParams();

  const initialList = useMemo(() => channels.map(channel => ({ ...channel, chosen: true })), [channels]);
  const [list, setList] = useState(initialList);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setList(initialList);
  }, [initialList, setList]);

  const [draggedOrder, setDragged] = useState(null);
  const [relatedOrder, setRelated] = useState(null);

  const sortChannels = useDispatch(playlists.actions.sortChannels);
  const handleOnEnd = useCallback(async ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      setLoading(true);
      await sortChannels({ playlistUUID, oldIndex: draggedOrder, newIndex: relatedOrder })
      setLoading(false);
    }
  }, [sortChannels, draggedOrder, relatedOrder, setLoading, playlistUUID]);

  return (
    <>
      {isLoading && <div className="absolute w-full h-full bg-black opacity-90 cursor-wait top-0 left-0 z-10" />}
      <ReactSortable
        list={list}
        setList={setList}
        className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 5xl:grid-cols-8 auto-rows-fr gap-2"
        onEnd={handleOnEnd}
        onMove={(e) => {
          setDragged(e.dragged.firstChild.dataset.value);
          setRelated(e.related.firstChild.dataset.value);
          return e;
        }}
      >
        {list.map(({ uuid }) => <div key={uuid}><ChannelCard uuid={uuid} isDnD  /></div>)}
      </ReactSortable>
    </>
  );
};


export const Playlist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filter, setFilter] = useRecoilState(atoms.playlists.selectedFilter);
  const [edit, toggleEdit] = useRecoilState(atoms.playlists.isEdit);
  const [page, setPage] = useState(0);
  const perPage = 30;
  const isHidden = useMemo(() => filter === 'hidden', [filter]);

  const { playlistUUID } = useParams();

  const isLoading = useSelector(playlists.selectors.isLoading);
  const keys = useSelector(playlists.selectors.channelsKeys);
  const hasHiddenChannels = useSelector(playlists.selectors.hasHiddenChannels);
  const playlistKeys = useSelector(playlists.selectors.keys);
  const isActiveUser = useSelector(account.selectors.isActiveUser);

  const fetchChannels = useDispatch(playlists.actions.fetchChannels);
  const clearSelectedPlaylist = useDispatch(playlists.actions.clearSelectedPlaylist);

  useEffect(() => {
    fetchChannels(playlistUUID);

    return () => {
      clearSelectedPlaylist();
    }
  }, [playlistUUID, fetchChannels]);

  useEffect(() => {
    if(!includes(playlistKeys, playlistUUID) && !isEmpty(playlistKeys)) {
      navigate({ pathname: `/app/playlists/${first(playlistKeys)}` }, { replace: true });
    }
  }, [playlistUUID, playlistKeys, navigate]);

  useEffect(() => {
    if(!hasHiddenChannels && filter === 'hidden') {
      setFilter(null);
    }
  }, [filter, setFilter, hasHiddenChannels])

  const list = useMemo(() => keys.filter((key) => {
    if(filter === 'hidden') {
      return key.hidden === true;
    }
    if(isNumber(filter)) {
      return key.playlist_group_id === filter && key.hidden !== true;
    }
    return key.hidden !== true;
  }), [keys, filter]);

  const paginateList = slice(list, 0, (page + 1) * perPage);
  const hasMore = useMemo(() => list.length > paginateList.length, [list, paginateList]);
  const handleNextPage = () => setPage(page + 1);

  if (isLoading) return <span>{t('playlists.loading')}</span>;

  if (!isLoading && isEmpty(keys)) return <span className="whitespace-pre-wrap">{t('playlists.emptyPlaylist')}</span>;

  return (
    <div id="playlist-data" className="flex flex-col md:flex-row mb-4 mr-4">
      <Categories />
      <div id="playlist-channels" className="flex-auto relative bg-black">
        <nav className="playlist-visibility-categories mb-5 bg-black z-10 sticky top-0">
          <ul className="flex text-sm md:text-base border-b border-solid border-gray-900 py-3 -mt-3 md:mr-3.5">
            <li className="mr-2 md:mr-11">
              <Button
                className={classNames({'text-yellow-400': filter === null})}
                onClick={() => setFilter(null)}
              >{t('playlists.allChannels')}</Button></li>
            {hasHiddenChannels && !edit && (
              <li>
                <Button
                  className={classNames({ 'text-yellow-400': isHidden})}
                  onClick={
                    () => {
                      setFilter('hidden');
                      setPage(0);
                    }
                  }>{t('playlists.hidden')}</Button>
              </li>
            )}

            {isActiveUser && !isHidden && (
              <li className="ml-auto">
                {edit
                  ? <Button onClick={() => toggleEdit(false)}>{t('buttons.save')}</Button>
                  : <Button onClick={() => toggleEdit(true)}>{t('buttons.edit')}</Button>
                }
              </li>
            )}
          </ul>
        </nav>

        {
          edit ? (
            <SortableList channels={list} />
          ) : (
            <InfiniteScroll
              className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7 5xl:grid-cols-8 auto-rows-fr gap-2"
              hasMore={hasMore}
              loader={<h6 key="lorem-ipsum">Loading...</h6>}
              pageStart={0}
              loadMore={handleNextPage}
            >
              {paginateList.map(({ uuid }) => <ChannelCard key={uuid} uuid={uuid} />)}
            </InfiniteScroll>
          )
        }
      </div>
    </div>
  );
}
