import { configureStore } from '@reduxjs/toolkit';

import { account } from '@redux/account';
import { playlists } from '@redux/playlists';

export default configureStore({
  reducer: {
    user: account.reducer,
    playlists: playlists.reducer,
  },
});
