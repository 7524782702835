import React, { useMemo } from 'react';
import classNames from 'classnames';

import { IconButton } from '@ui/Button/IconButton';
import { DDropdown } from '@ui/Dropdown';
import { useTranslation } from 'react-i18next';

export const PlaylistCardUI = ({
  onClick,
  title,
  subtitle,
  icon,
  sourceType,
  imgSrc,
  isLoading,
  isActive,
  handleEditClick,
  handleSyncClick,
  handleDeleteClick,
  hideActions,
  className,
}) => {
  const { t } = useTranslation();

  const fileMenu = useMemo(() => ([
    {
      title: t('buttons.edit'),
      fn: handleEditClick,
    },
    {
      title: t('buttons.delete'),
      fn: handleDeleteClick,
    },
  ]), [t, handleEditClick, handleDeleteClick]);

  const menu = useMemo(() => {
    return sourceType === 0 ? [
      {
        title: t('buttons.update'),
        fn: handleSyncClick,
      },
      ...fileMenu,
      ] : fileMenu;
  }, [fileMenu, sourceType, t, handleSyncClick]);

  return (
    <span
      onClick={onClick}
      className={classNames(
        { 'border border-yellow-400 border-solid' : isActive},
        'flex relative w-48 h-full cursor-pointer p-4 pr-5 bg-gray-900 rounded-lg text-xs',
        className,
      )}
    >
      {
        icon ? icon : (
          <img
            src={imgSrc}
            title={`${title} icon`}
            alt={`${title} icon`}
            className="w-8 h-8 overflow-hidden mr-4 rounded-lg flex-none"
          />
        )
      }
      <span className="flex flex-col overflow-hidden">
        <span
          className={
            classNames(
              {
                'text-white' : !isActive,
                'text-yellow-400': isActive,
                'text-gray-500': isLoading,
              },
              'font-medium whitespace-nowrap overflow-hidden text-ellipsis'
            )
          }
          title={title.length > 10 ? title : ''}
        >{title}
        </span>
        <span
          className={
            classNames(
              { 'text-yellow-400': isActive, 'text-gray-500': isLoading },
              'whitespace-nowrap'
            )
          }
        >{isLoading ? t('playlists.updating') : subtitle}</span>
      </span>
      {!hideActions && (
        <span className="whitespace-nowrap absolute right-1 top-0 h-full inline-flex items-center">
        <DDropdown menu={menu}>
          <IconButton
            name="bbq"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            fill={isActive ? '#fff' : 'currentColor'}
            stroke={isActive ? '#fff': 'currentColor'}
          />
        </DDropdown>
      </span>
      )}
    </span>
  );
};
