import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { useDispatch } from 'utils/useDispatch';

import { ChannelCardUI } from './ChannelCardUI';

export const ChannelCard = ({ uuid, isDnD }) => {
  const { playlistUUID } = useParams();
  const isEdit = useRecoilValue(atoms.playlists.isEdit);
  const makeChannelSelector = useMemo(playlists.selectors.makeChannelSelector, []);
  const { name, favorite, hidden, order, thumbnail } = useSelector(state => makeChannelSelector(state, uuid ));
  const isActiveUser = useSelector(account.selectors.isActiveUser);

  const updateFavorite = useDispatch(playlists.actions.updateFavorite);
  const toggleHideChannel = useDispatch(playlists.actions.toggleHideChannel);

  const handleUpdateFavorite = useCallback(() =>
    updateFavorite({ playlistUUID, channelUUID: uuid, favStatus: !favorite }),
    [favorite, playlistUUID, uuid, updateFavorite]);

  const handleHide = useCallback(() => {
    toggleHideChannel({ playlistUUID, channelUUID: uuid, isHidden: hidden })
  }, [playlistUUID, uuid, hidden, toggleHideChannel]);

  return (
    <ChannelCardUI
      className={isDnD ? 'cursor-move h-full': ''}
      title={name}
      thumbnail={thumbnail}
      order={order}
      isFavorite={favorite}
      isEdit={isEdit}
      isHidden={hidden}
      hideActions={!isActiveUser}
      handleHide={handleHide}
      handleUpdateFavorite={handleUpdateFavorite}
    />
  );
};
