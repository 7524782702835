import classNames from 'classnames';
import React from 'react';
import { Icon } from '../Icon/Icon';

export const Button = ({
  className,
  children,
  size,
  truncate,
  block,
  center,
  view,
  icon,
  align,
  isDisabled,
  ...rest
}) => {
  const sizes = {
    small: 'text-sm py-1 px-2.5',
    middle: 'text-sm py-2 px-3.5',
  };

  const views = {
    primary: 'bg-yellow-400',
    outline: 'text-yellow-400 border-yellow-400',
    secondary: 'bg-gray-900 color-gray-700',
    danger: 'text-red-400',
    promo: 'border-red-400 text-red-400',
  };

  const alignment = {
    center: 'justify-center',
    left: 'justify-start',
  }
  return (
    <button
      className={
        classNames(
          'inline-flex items-center rounded-md border focus:outline-none',
          size && sizes[size],
          {'truncate' : truncate},
          {'w-full': block},
          {'disabled:text-gray-500 disabled:border-gray-500 cursor-not-allowed': isDisabled},
          views[view],
          alignment[align],
          className,
        )}
      disabled={isDisabled}
      {...rest}
    >
      {icon && <Icon name={icon}/>}
      <span className={classNames({ 'ml-2': icon }, 'truncate')}>
        {children}
      </span>
    </button>
  );
};

Button.defaultProps = {
  align: 'center',
}
