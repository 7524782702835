import React from 'react';

import { SVG } from './SVG';

export const BBQ = ({ className, stroke, fill }) => (
  <SVG className={className} width={24} fill={fill}>
    <g id="arrow-down">
      <path id="Oval" fillRule="evenodd" clipRule="evenodd" d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke={stroke} />
      <path id="Oval_2" fillRule="evenodd" clipRule="evenodd" d="M11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6Z" stroke={stroke} />
      <path id="Oval_3" fillRule="evenodd" clipRule="evenodd" d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z" stroke={stroke} />
    </g>
  </SVG>
);

