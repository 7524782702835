import { createContext, useContext, useEffect, useState } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import { get } from 'lodash';

const PaddleContext = createContext();

export const PaddleProvider = ({ user, handleComplete, children })  => {
  const isLocal = process.env.REACT_APP_ENV !== 'production';

  const [paddle, setPaddle] = useState(null);
  const [isPaddleOrdering, setPaddleOrdering] = useState(false);
  const [isPaddleReady, setIsPaddleReady] = useState(false);

  const paddleSettings = {
    environment: isLocal ? 'sandbox' : 'production',
    debug: isLocal,
    token: isLocal ? 'test_68395b652b111fa42f68dc0be04' : 'live_57e91e0457cf1a599d8645aae06',
    eventCallback: (data) => {
      if(get(data, 'name') === 'checkout.loaded')  {
        setIsPaddleReady(true);
      }
      if(get(data, 'name') === 'checkout.completed')  {
        setTimeout(() => {
          setPaddleOrdering(false);
          handleComplete(data);
        }, 3000);
      }
    },
    checkout: {
      settings: {
        displayMode: 'inline',
        theme: 'dark',
        locale: 'en',
        allowLogout: false,
        frameTarget: 'checkout-container',
        frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;'
      }
    }
  };

  useEffect(() => {
    initializePaddle(paddleSettings).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [paddleSettings]);

  const handlePaddleClick = (items) => {
    setPaddleOrdering(true);
    const input = {
      items,
      customer: {
        email: user.email,
        address: {
          countryCode: "US",
          postalCode: "10021",
          region: user?.profile?.city || 'New York',
        },
      },
      customData: {
        user_id: user.id,
      }
    };

    paddle?.Checkout.open(input);
  }

  const value = {
    handlePaddleClick,
    isPaddleReady,
    setPaddleOrdering,
    isPaddleOrdering,
  };

  return (
    <PaddleContext.Provider value={value}>
      {children}
    </PaddleContext.Provider>
  );
}

export const usePaddle = () => {
  const context = useContext(PaddleContext);
  if (!context) {
    throw new Error('usePaddle must be used within a PaddleProvider');
  }
  return context;
}
