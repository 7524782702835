import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { get } from 'lodash';

import { atoms } from '@recoil/atoms';
import { account } from '@redux/account';
import { Button } from '@ui/Button/Button';
import { useDispatch } from 'utils/useDispatch';

import { usePaddle } from '../PaddleProvider';

export const Payment = ({
  price,
  order,
  clearBasket,
  payPalDisabled,
  currencySign,
  currencyCode,
  hasWorldwidePayment,
}) => {
  const [highlightRestriction, setHighlight] = useState();
  const { t } = useTranslation();
  const setPending = useSetRecoilState(atoms.layout.pending);
  const { handlePaddleClick } = usePaddle();
  const userData = useSelector(account.selectors.user);
  const savePayPalTransaction = useDispatch(account.actions.savePayPalTransaction);
  const sendFailLog = useDispatch(account.actions.sendFailLog);
  const getSignature = useDispatch(account.actions.getSignature);
  const getFreeKassaURL = useDispatch(account.actions.getFreeKassaURL);
  const planIDs = useMemo(() => order.map(({ id }) => id), [order]);
  const settings = useSelector(account.selectors.settings);
  const payPalId = get(settings, 'paypal_id', 'AXbqR1dMNHTulMRHIsWq-Af1g_wl1AuopkorXsrqCxBVC1Sf7O5mcuQISxSnP2OleRqCkv43V5yA8SS7')
  const isDemo = get(settings, 'is_demo', false);

  const handleWalletOneClick = async () => {
    const { payload } = await getSignature(planIDs);
    const form = document.createElement('form');
    form.style.display = 'none';
    form.method = 'post';
    form.action = 'https://wl.walletone.com/checkout/checkout/Index';

    Object.keys(payload).map(k => {
      let input = document.createElement('input');
      input.type = 'text';
      input.name = k;
      input.value = payload[k];
      form.appendChild(input);
      return null;
    });

    document.body.appendChild(form);
    setTimeout(() => form.submit(), 200);
  }

  const handleFreeKassa = async () => {
    const { payload } = await getFreeKassaURL(planIDs);
    window.location.href = payload;
  };

  const handlePaddle = () => {
    const groupedByPriceId = order.reduce((acc, item) => {
      acc[item.paddle_price_id] = (acc[item.paddle_price_id] || 0) + 1;
      return acc;
    }, {});
    const result = Object.entries(groupedByPriceId).map(([key, value]) => ({ priceId: key, quantity: value }));
    handlePaddleClick(result);
  }

  const createOrder = (data, actions) => {
    if(payPalDisabled) return null;
    return actions.order.create({
      purchase_units: [
        {
          description: 'UniPlayer Subscription',
          amount: {
            currency_code: currencyCode,
            value: price,
          }
        }
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const onApprove = async (data, actions) => {
    await actions.order.capture();
    await savePayPalTransaction({ orderId: data.orderID, planIDs, isDemo });
    return clearBasket();
  };

  const onError = async (e) => {
    await sendFailLog({
      data: JSON.stringify(e),
      userId: userData.id,
      name: 'paypal_payment',
      type: 'payment_failure',
    });
    setPending(false);
  }

  return (
    <PayPalScriptProvider
      options={{
        'client-id': payPalId,
        currency: currencyCode,
      }}
    >
      {
        hasWorldwidePayment ? (
          <div className="mb-4">
            <Button
              className="p-2.5 bg-yellow-400 text-black font-bold"
              block
              onClick={handlePaddle}
            >{t('plans.paddle')}</Button>
          </div>
        ) : (
          <div className="mb-4">
            <Button
              className="p-2.5 bg-yellow-400 text-black font-bold"
              block
              onClick={handleFreeKassa}
            >{t('plans.payWith')} FreeKassa</Button>
          </div>
        )
      }

      <div className="relative z-0">
        {payPalDisabled && (
          <div
            className="absolute w-full h-full bg-black bg-opacity-60 cursor-not-allowed z-10000"
            onClick={() => setHighlight(true)}
          />
        )}
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          forceReRender={[ price ]}
        />
      </div>
      <div className="relative bg-black z-10">
        {
          payPalDisabled && (
            <div
              className={classNames({
                  'text-xs': !highlightRestriction,
                  'text-md': highlightRestriction,
                },
                'text-white text-center mb-4 transition-all'
              )}
            >* <Trans i18nKey="plans.paypalRestriction" values={{ currencySign }} />
              {t('')}</div>
          )
        }
      </div>
    </PayPalScriptProvider>
  );
}
