import React from 'react';

import { Icon } from '@ui/Icon/Icon';
import { Button } from './Button';

export const IconButton = ({ className, iconClassName, name, spin, fill, stroke, onClick, width, height }) => {
  return (
    <Button onClick={onClick} className={className}>
      <Icon className={iconClassName} name={name} spin={spin} fill={fill} stroke={stroke} width={width} height={height} />
    </Button>
  );
}
