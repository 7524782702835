import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { account } from '@redux/account';
import { Button } from '@ui/Button/Button';
import { Modal } from '@ui/Modal/Modal';
import { Input } from '@ui/Form/Input';
import { useDispatch } from '../../utils/useDispatch';
import { useI18n } from '../../utils/useI18n';
import { Alert } from '../../@ui/Alert';

export const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, toggleModal] = useState(false);
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const [confirmName, setConfirmName] = useState();
  const [isPasswordFormDisabled, setPasswordFormDisabled] = useState(false);
  const [isUserFormDisabled, setUserFormDisabled] = useState(false);

  const user = useSelector(account.selectors.user);
  const balance = useSelector(account.selectors.balance);
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const hasError = useSelector(account.selectors.hasError);
  const firstError = useSelector(account.selectors.firstError);
  const i18nKey = useSelector(account.selectors.i18nKey);

  const updateUser = useDispatch(account.actions.updateUser);
  const deleteAccount = useDispatch(account.actions.deleteAccount);
  const changePassword = useDispatch(account.actions.changePassword);
  const setError = useDispatch(account.actions.setError);

  const handleCloseModals = useCallback(() => {
    toggleModal(false);
    toggleConfirmModal(false);
  }, []);

  const handleDeleteAccount = useCallback(() => {
    deleteAccount();
    handleCloseModals();
    navigate('/auth/logout');
  }, []);

  const handleApprove = useCallback(() => {
    if (isActiveUser) {
      toggleConfirmModal(true);
    } else {
      handleDeleteAccount();
    }
    toggleModal(false);
  }, [isActiveUser]);

  const handleConfirmName = useCallback(({ target: { value }}) => setConfirmName(value), []);

  const userNameMatch = useMemo(() => get(user, 'name') === confirmName, [user, confirmName]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: get(user, 'name'),
      email: get(user, 'email'),
    },
    onSubmit: async ({ name, email }, { resetForm }) => {
      setUserFormDisabled(true);
      await updateUser({ name, email });
      setUserFormDisabled(false);
      return resetForm();
    },
  });

  const passwordFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async ({ oldPassword, newPassword, confirmPassword, }, { resetForm }) => {
      if(isEmpty(oldPassword) || isEmpty(newPassword) || isEmpty(confirmPassword)) {
        return null;
      }
      if(newPassword !== confirmPassword) {
        return setError(t('settings.passwordMismatch'));
      }
      await changePassword({ oldPassword, newPassword, confirmPassword });
      setPasswordFormDisabled(false);
      return resetForm();
    }
  })

  const responseMessage = useI18n(i18nKey, null);

  if(!user) return null;
  return (
    <div className="w-80 flex flex-col mx-auto py-16">
      <h1 className="text-2xl mb-10">{t('navBar.settings')}</h1>
      { (hasError || i18nKey) && <Alert className="mb-10" type={hasError ? 'danger' : 'success'}>{firstError || responseMessage || 'Server Error'}</Alert> }

      {
        isActiveUser && (
          <>
            <Input
              className="mb-4"
              type="text"
              name="name"
              placeholder={t('fields.name')}
              label={t('fields.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <Input
              className="mb-4"
              type="email"
              name="email"
              placeholder="Email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <Button
              className="mb-12"
              view="outline"
              size="middle"
              onClick={formik.handleSubmit}
              isDisabled={isUserFormDisabled}
            >{t('buttons.saveChanges')}</Button>
          </>
        )
      }

      <form className="w-full flex flex-col" onSubmit={passwordFormik.handleSubmit}>
        <Input
          className="mb-4"
          type="password"
          name="oldPassword"
          placeholder={t('fields.oldPassword')}
          label={t('fields.changePassword')}
          onChange={passwordFormik.handleChange}
          value={passwordFormik.values.oldPassword}
        />
        <Input
          className="mb-4"
          type="password"
          name="newPassword"
          placeholder={t('fields.newPassword')}
          onChange={passwordFormik.handleChange}
          value={passwordFormik.values.newPassword}
        />
        <Input
          className="mb-4"
          type="password"
          name="confirmPassword"
          placeholder={t('fields.confirmPassword')}
          onChange={passwordFormik.handleChange}
          value={passwordFormik.values.confirmPassword}
        />
        <Button
          className="mb-6"
          view="outline"
          size="middle"
          onClick={passwordFormik.handleSubmit}
          disabled={isPasswordFormDisabled}
        >{t('buttons.savePassword')}</Button>
      </form>

      <Button className="mb-6" view="danger" size="middle" onClick={() => toggleModal(true)}>{t('buttons.deleteAccount')}</Button>

      {isModalOpen && (
        <Modal
          center
          title={t('settings.deleteAccount.title')}
          handleCloseModal={handleCloseModals}
          footerButtons={(
            <>
              <Button view="ghost" size="middle" onClick={handleCloseModals}>{t('buttons.close')}</Button>
              <Button view="outline" size="middle" onClick={handleApprove}>{t('buttons.sure')}</Button>
            </>
          )}
        >
          <p className="text-sm">{t('settings.deleteAccount.description')}</p>
        </Modal>
      )}

      {isConfirmModalOpen && (
        <Modal
          center
          title={t('settings.confirmDeleteModal.title')}
          handleCloseModal={handleCloseModals}
          footerButtons={(
            <>
              <Button view="ghost" size="middle" onClick={handleCloseModals}>{t('buttons.close')}</Button>
              <Button view="outline" size="middle" isDisabled={!userNameMatch} onClick={handleDeleteAccount}>{t('buttons.delete')}</Button>
            </>
          )}
        >
          <p className="text-sm">
            <Trans i18nKey="settings.confirmDeleteModal.description" values={{ balance, username: get(user, 'name') }} /></p>
          <div className="text-sm">
            <Input
              className="w-full my-4"
              type="name"
              name="name"
              onChange={handleConfirmName}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
