import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

import './i18n/i18n';
import { AuthWrapper } from './containers/Auth/AuthWrapper';
import { Layout } from './containers/App/Layout';
import { Logout } from './containers/Auth/Logout';

ReactGA.initialize('G-QRTETS4MSJ');

export const history = createBrowserHistory();

const App = () => (
  <BrowserRouter history={history}>
    <Routes>
      <Route path="/" element={<Navigate to="/app/playlists" />} />
      <Route path="/auth/logout" element={<Logout />} />
      <Route path="/auth/*" element={<AuthWrapper />} />

      <Route path="/app/*" element={<Layout />} />
    </Routes>
  </BrowserRouter>
);

export default App;
