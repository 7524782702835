import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { account } from '@redux/account';
import { Icon } from '@ui/Icon/Icon';
import { DDropdown } from '@ui/Dropdown';
import { useDispatch } from 'utils/useDispatch';

export const LanguageDropdown = ({ className }) => {
  const language = useSelector(account.selectors.language);

  const setLanguage = useDispatch(account.actions.setLanguage);

  const handleLangChange = useCallback((lng) => {
    setLanguage(lng);
    i18next.changeLanguage(lng);
  }, []);

  const languages = [
    { code: 'en', title: 'English' },
    { code: 'ru', title: 'Русский' },
    { code: 'uk', title: 'Українська' },
    { code: 'de', title: 'Deutsch' },
    { code: 'es', title: 'Español' },
    { code: 'fr', title: 'Français' },
    { code: 'it', title: 'Italiano' },
    { code: 'nl', title: 'Nederlands' },
    { code: 'tr', title: 'Türkçe' },
    { code: 'pl', title: 'Polski' },
    { code: 'pt', title: 'Português'}
  ];

  const languageMenu = languages.map((lang) => ({
    title: lang.title,
    fn: () => handleLangChange(lang.code)
  }));

  return (
    <DDropdown menu={languageMenu}>
      <span className={`cursor-pointer inline-flex items-center ${className}`}>{language}<Icon name="chevron-xs" className="ml-1" /></span>
    </DDropdown>
  );
}
