import React, { useState, useEffect, createContext } from 'react';
import { throttle } from 'lodash';

const isMobileDevice = () => window.innerWidth <= 640;

export const SizeContext = createContext();

export const SizeProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const handleResize = throttle(() => {
    setIsMobile(isMobileDevice());
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return <SizeContext.Provider value={{ isMobile }}>{children}</SizeContext.Provider>
};
