import { atom } from 'recoil';


export const atoms = {
  layout: {
    sidebarCollapse: atom({
      key: 'layout/sidebarCollapse',
      default: false,
    }),
    pending: atom({
      key: 'layout/pending',
      default: false,
    }),
  },
  playlists: {
    modal: atom({
      key: 'playlists/modal',
      default: false,
    }),
    editedPlaylistUUID: atom({
      key: 'playlists/editedPlaylistUUID',
      default: null,
    }),
    selectedFilter: atom({
      key: 'playlists/selectedFilter',
      default: null,
    }),
    isEdit: atom({
      key: 'playlists/isEdit',
      default: false,
    }),
  },
}
