import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@ui/Button/Button';
import { account } from '@redux/account';

import { usePaddle } from '../PaddleProvider';
import { Plan } from './Plan';
import { Cart } from './Cart';
import { PromoSection } from './PromoSection';
import { getCurrencySymbol } from './getCurrencySymbol';
import { BalanceInfo } from './BalanceInfo';
import { get } from 'lodash';

export const Plans = ({ plans, order, setBasket }) => {
  const { t } = useTranslation();
  const { isPaddleOrdering, setPaddleOrdering } = usePaddle();

  const [promoType, showPromo] = useState(null);
  const isPromoShown = promoType > 0;

  const includedDays = useSelector(account.selectors.includedDays);
  const subscriptionExpiresAt = useSelector(account.selectors.subscriptionExpiresAt);

  const settings = useSelector(account.selectors.settings);
  const features = useSelector(account.selectors.features);
  const hasWorldwidePayment = get(features, 'usePaddle', false);
  const currencyCode = settings?.currency || 'USD';
  const currencySign = getCurrencySymbol(currencyCode);

  useEffect(() => {
    if (get(settings, 'promo')) {
      showPromo(settings.promo);
    }
  }, [settings]);

  const { totalDays, totalPrice } = useMemo(() => order.reduce((prev, current) => ({ totalDays: prev.totalDays + current.days_included, totalPrice: prev.totalPrice + current.priceUSD}), { totalDays: 0, totalPrice: 0}), [order]);

  const handleAddToBasket = useCallback((planId) => {
    const plan = plans.find(({ id }) => id === planId);
    setBasket([...order, plan]);
  }, [order, plans, setBasket]);

  return (
    <div className="grid md:grid-cols-3 md:gap-4 lg:gap-8 mb-6 mr-6 2xl:mr-20" data-test="plans-container">
      <div className={classNames('mb-8 order-2', { 'hidden': isPaddleOrdering })}>
        <Plan
          title={isPromoShown ? t('plans.annual+') : t('plans.annual')}
          price={20}
          currencySign={currencySign}
          onClick={() => handleAddToBasket(5)}
          isActive
          isYear
          promoType={promoType}
        />
      </div>
      <div className={classNames('mb-8 order-2', { 'hidden': isPaddleOrdering})}>
        <Plan
          title={t('plans.monthly')}
          price={2}
          currencySign={currencySign}
          onClick={() => handleAddToBasket(2)}
        />
      </div>
      <div className={classNames('col-span-2 h-full', { 'hidden': !isPaddleOrdering })}>
        <header className="mb-4 leading-none" data-test="back-to-plans-header">
          <Button icon="chevronLeft" className="font-bold text-yellow-400" onClick={() => setPaddleOrdering(false)}>
            {t('plans.backToPlans')}
          </Button>
        </header>
        <div className="checkout-container rounded-lg bg-gray-900 py-6 mb-4"></div>
      </div>
      <div className="mb-8 order-1 md:order-3">
        <BalanceInfo isPaddle={isPaddleOrdering} includedDays={includedDays} subscriptionExpiresAt={subscriptionExpiresAt}  />
        {!isPaddleOrdering && <PromoSection />}
        {
          totalDays > 0 && !isPaddleOrdering && (
            <Cart
              currencySign={currencySign}
              currencyCode={currencyCode}
              totalPrice={totalPrice}
              totalDays={totalDays}
              setBasket={setBasket}
              handleAddToBasket={handleAddToBasket}
              order={order}
              hasWorldwidePayment={hasWorldwidePayment}
            />
          )
        }
      </div>
    </div>
  );
};
