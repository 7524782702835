import React from 'react';
import { Icon } from '../../../@ui/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

export const BalanceInfo = ({ isPaddle, includedDays, subscriptionExpiresAt }) => {
  const { t } = useTranslation();

  if (!isNumber(includedDays)) return null;

  return (
    <div className={`w-full bg-gray-900 rounded-lg py-5 text-center text-sm text-gray-700 mb-6 ${isPaddle ? 'mt-[42px]' : ''}`} data-test="balance-info">
      <Icon name="check" className="w-full" />
      <h3 className="text-green-500 text-xl font-bold">{includedDays} {t('plans.daysLeft')}</h3>
      <h5>{t('plans.expiresOn')} {subscriptionExpiresAt}</h5>
    </div>
  );
};
