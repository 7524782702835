import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { account } from '@redux/account';
import { Separator } from '@ui/Separator';
import { Link } from '@ui/Link/Link';
import { SignInWithApple } from '@ui/Button/SignInWithApple';
import { Alert } from '@ui/Alert';
import { Input } from '@ui/Form/Input';
import { Button } from '@ui/Button/Button';
import { useDispatch } from 'utils/useDispatch';
import { useQuery } from 'utils/useQuery';
import { LanguageDropdown } from 'components/LanguageDropdown';

export const Login = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const getParams = useQuery();
  const searchQuery = !isEmpty(getParams.toString()) ? `?${getParams.toString()}` : '';

  const queryString = get(location, 'search').substring(1);
  const { token } = qs.parse(queryString);

  const accessToken = useSelector(account.selectors.accessToken);
  const hasError = useSelector(account.selectors.hasError);
  const firstError = useSelector(account.selectors.firstError);

  const login = useDispatch(account.actions.login);
  const adminLogin = useDispatch(account.actions.adminLogin);
  const fetchUser = useDispatch(account.actions.fetchUser);
  const setAccessToken = useDispatch(account.actions.setAccessToken);
  const clearErrors = useDispatch(account.actions.clearErrors);

  const isLocal = process.env.REACT_APP_ENV !== 'production';

  const handleNavigate = useCallback(() => {
    let { from } = location.state || { from: { pathname: "/app/playlists" } };
    navigate(from, { replace: true });
      }, [location.state, navigate]);

  useEffect(() => {
    if(!isEmpty(accessToken)) {
      setAccessToken(accessToken);
      fetchUser();
      return handleNavigate();
    }

    if(token) {
      setAccessToken(token);
      fetchUser();
      return handleNavigate();
    }
  }, [accessToken, token, setAccessToken, fetchUser, handleNavigate]);

  useEffect(() => {
    return () => clearErrors();
  }, [clearErrors]);

  const handleAdminClick = () => adminLogin();

  return (
    <>
      <header className="flex flex-col space-y-4">
        <div className="flex items-center justify-between mb-8 text-white">
          <h1 className="text-3xl text-center font-bold">{t('auth.logIn')}</h1>
          <LanguageDropdown />
        </div>
        {hasError && <Alert type="danger">{firstError}</Alert>}
        <SignInWithApple />
      </header>
      <Separator className="my-4">{t('common.or')}</Separator>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={login}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              className="w-full"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
            <Input
              className="w-full"
              type="password"
              name="password"
              placeholder={t('fields.password')}
              onChange={handleChange}
              value={values.password}
            />
            <Button
              type="submit"
              view="primary"
              className="font-bold text-base p-2"
              center
              block
            >{t('auth.logInButton')}</Button>
            <div className="text-center">
              <Link
                className="text-yellow-400"
                to="/auth/reset"
              >
                {t('auth.forgotLink')}
              </Link>
            </div>
          </form>
        )}
      </Formik>
      <Separator className="my-4" />
      {isLocal && (
        <Button
          view="primary"
          className="font-bold text-base p-2 mb-4"
          center
          block
          onClick={handleAdminClick}
        >Admin Log in</Button>
      )}
      <div className="text-gray-500">
        {t('auth.signUpTitle')}&nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          className="text-yellow-400"
          to={{
            pathname: '/auth/register',
            search: searchQuery,
          }}
        >{t('auth.signUp')}</Link>
      </div>
    </>
  );
}
