import React from 'react';
import { useSelector } from 'react-redux';

import { account } from '@redux/account';

import { Device } from './Device';

export const Devices = () => {
  const devices = useSelector(account.selectors.devices);

  return (
    <>
      {devices.map(({ id, device_name, device_token, device_model, app_version, created_at }) =>
        <Device
          key={device_token}
          id={id}
          name={device_name}
          token={device_token}
          model={device_model}
          appVersion={app_version}
          createdAt={created_at}
        />
      )}
    </>
  );
}
