import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Reset } from './Reset';
import { SignUp } from './SignUp';
import { Login } from './Login';

export const AuthWrapper = () => {
  return (
    <div className="auth-wrapper w-80 flex flex-col mx-auto py-16">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/reset" element={<Reset />} />
      </Routes>
    </div>
  );
}
