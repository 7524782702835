import classNames from 'classnames';
import React from 'react';

import { IconButton } from '../Button/IconButton';

export const Modal = ({ title, handleCloseModal, children, center, footerButtons }) => {
  return (
    <div className="z-50 w-screen h-screen fixed top-0 left-0">
      <div
        className="overlay bg-black opacity-80 w-full h-full fixed top-0 left-0" style={{ zIndex: -1}}
        onClick={handleCloseModal}
      />
      <div className={classNames('w-full md:w-120 bg-gray-900 mx-auto mt-20')}>
        {title && (
          <header className="py-3.5 pr-5 pl-6 flex text-sm text-white font-bold border-b border-gray-800">
            <h2>{title}</h2>
            <IconButton name="times" className="ml-auto" onClick={handleCloseModal} />
          </header>
        )}
        {children && (
          <div className="p-6 flex flex-col">{children}</div>
        )}

        {footerButtons && (
          <footer className="border-t border-gray-800 px-6 py-2 flex">
            <div className="ml-auto space-x-2">
              {footerButtons}
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};
