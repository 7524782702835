import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { useDispatch } from 'utils/useDispatch';
import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { Button } from '@ui/Button/Button';
import { IconButton } from '@ui/Button/IconButton';
import { get } from 'lodash';

export const CategoryButton = ({ id, title, hideActions }) => {
  const { playlistUUID } = useParams();
  const [selectedFilter, setFilter] = useRecoilState(atoms.playlists.selectedFilter);
  const keys = useSelector(playlists.selectors.channelsKeys);
  const features = useSelector(account.selectors.features);
  const hasV3 = get(features, 'v3', false);

  const hideCategory = useDispatch(playlists.actions.hideCategory);

  const categoryCount = keys.filter(key => key.playlist_group_id === id && !key.hidden).length;

  if(categoryCount === 0 ) return null;

  const handleHide = () => {
    hideCategory({ playlistUUID, groupId: id });
  };

  const handleClick = () => {
    setFilter(selectedFilter === id ? null : id);
  }

  return (
    <li
      className={classNames(
        {'border-yellow-400 text-yellow-400' : id === selectedFilter},
        'flex group mb-2 hover:bg-gray-900 border rounded-md pr-0.5'
      )}
        key={id}>
      <Button
        size="small"
        truncate
        block
        align="left"
        className="hover:text-yellow-400 pr-0"
        onClick={handleClick}
      >
        {title}
      </Button>
      {hasV3 && (
        <IconButton className="scale-75 ml-auto text-transparent hover:!text-yellow-400 group-hover:text-gray-500" name="eyeCrossed" onClick={handleHide} />
      )}
    </li>
  );
}
