import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { keys, keyBy, toArray, omit, get } from 'lodash';

import { api } from 'api';
import { getChannelsByKeys } from './utils';

const initialState = {
  playlists: {},
  favorites: {},
  playlistsKeys: [],
  selectedPlaylist: {
    channels: {},
    categories: [],
    channelsKeys: {},
  },
  error: {
    status: null,
    errorKey: null,
  },
  isLoading: false,
};

const actions = {
  fetchPlaylists: createAsyncThunk(
    'playlists/fetchPlaylists',
    () => api.get('playlists'),
  ),
  fetchFavorites: createAsyncThunk(
    'playlists/fetchFavorites',
    () => api.get('playlists/channels/favorites'),
  ),
  fetchChannels: createAsyncThunk(
    'playlists/fetchChannels',
    uuid => api.get(`playlists/${uuid}`)
  ),
  createPlaylist: createAsyncThunk(
    'playlists/createPlaylist',
    ({ data }, { rejectWithValue }) =>
      api.post('playlists', data).catch(e => rejectWithValue(e)),
  ),
  editPlaylist: createAsyncThunk(
    'playlists/editPlaylist',
    ({ uuid, data }) => api.post(`playlists/${uuid}`, data),
  ),
  mergePlaylist: createAsyncThunk(
    'playlists/mergePlaylist',
    ({ uuid: playlistUUID }) => api.post(`playlists/${playlistUUID}/merge`, { forced: true }),
  ),
  deletePlaylist: createAsyncThunk(
    'playlists/deletePlaylist',
    ({ uuid }) => api.delete(`playlists/${uuid}`)
  ),
  updateFavorite: createAsyncThunk(
    'playlists/updateFavorite',
    ({ playlistUUID, channelUUID, favStatus }) =>
      api.put(`playlists/${playlistUUID}/channels/${channelUUID}`, { favorite: favStatus })
  ),
  reorderFavorites: createAsyncThunk(
    'playlists/reorderFavorites',
    ({ oldIndex, newIndex }) =>
      api.post(`playlists/channels/sort`,
        { 'old_index': oldIndex, 'new_index': newIndex }
      ),
  ),
  toggleHideChannel: createAsyncThunk(
    'playlists/toggleHideChannel',
    ({ playlistUUID, channelUUID }) => api.post(`playlists/${playlistUUID}/channels/${channelUUID}/hide`),
  ),
  sortChannels: createAsyncThunk(
    'playlists/sortChannels',
    ({ playlistUUID, oldIndex, newIndex }) =>
      api.post(`playlists/${playlistUUID}/channels/sort`,
        { 'old_index': oldIndex, 'new_index': newIndex }
      ),
  ),
  hideCategory: createAsyncThunk(
    'playlists/hideCategory',
    ({ playlistUUID, groupId }) => api.post(`playlists/${playlistUUID}/groups/${groupId}/hide`),
  ),
};

const slice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    clearSelectedPlaylist: (state => {
      state.selectedPlaylist = initialState.selectedPlaylist;
    }),
    clearModal: (state => {
      state.error.status = null;
      state.error.errorKey = null;
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [actions.fetchPlaylists.fulfilled]: (state, { payload }) => {
      state.playlists = keyBy(payload, 'uuid');
      state.playlistsKeys = keys(keyBy(payload, 'uuid'));
    },
    [actions.fetchFavorites.fulfilled]: (state, { payload }) => {
      state.favorites = keyBy(payload, 'uuid');
    },
    [actions.fetchChannels.pending]: state => {
      state.isLoading = true;
    },
    [actions.fetchChannels.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
      state.selectedPlaylist.categories = payload.groups;
      state.selectedPlaylist.channelsKeys = getChannelsByKeys(payload.channels);
    },
    [actions.fetchChannels.rejected]: state => {
      state.isLoading = false;
    },
    [actions.sortChannels.fulfilled]: (state, { payload }) => {
      //todo: remove order and fav_order from channels and use it only in channelsKeys

      state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
      state.selectedPlaylist.channelsKeys = getChannelsByKeys(payload.channels);
    },
    // todo: combine actions here
    [actions.createPlaylist.pending]: state => {
      state.error.status = null;
      state.error.errorKey = null;
    },
    [actions.createPlaylist.fulfilled]: (state, { payload }) => {
      state.playlists[payload.uuid] = omit(payload, ['channels']);
      state.playlistsKeys.push(payload.uuid);
      // todo add channels
    },
    [actions.createPlaylist.rejected]: (state, { payload: { status, data } }) => {
      state.error.status = status;
      state.error.errorKey = get(data, 'i18n_key', null);
    },
    [actions.editPlaylist.pending]: state => {
      state.error.status = null;
    },
    [actions.editPlaylist.fulfilled]: (state, { payload }) => {
      state.playlists[payload.uuid] = omit(payload, ['channels']);
    },
    [actions.editPlaylist.rejected]: state => {
      state.error.status = 400;
    },
    [actions.mergePlaylist.pending]: (state, { meta: { arg }}) => {
      state.playlists[arg.uuid].isLoading = true;
    },
    [actions.mergePlaylist.fulfilled]: (state, { payload }) => {
      state.playlists[payload.uuid] = omit(payload, ['channels']);
    },
    [actions.mergePlaylist.rejected]: (state, { meta: { arg }}) => {
      state.playlists[arg.uuid].isLoading = false;
      state.error.status = 400;
    },
    [actions.deletePlaylist.fulfilled]: (state, { meta: { arg }}) => {
      const index = state.playlistsKeys.findIndex(i => i === arg.uuid);
      state.playlistsKeys.splice(index, 1);
    },
    [actions.updateFavorite.fulfilled]: (state, { payload }) => {
      if(state.selectedPlaylist.channels[payload.uuid]) {
        state.selectedPlaylist.channels[payload.uuid].favorite = payload.favorite;
      }
      if (payload.favorite) {
        state.favorites[payload.uuid] = payload;
      } else {
        delete state.favorites[payload.uuid];
      }
    },
    [actions.toggleHideChannel.pending]: (state, { meta: { arg } }) => {
      state.selectedPlaylist.channelsKeys[arg.channelUUID].hidden = !arg.isHidden;
    },
    [actions.toggleHideChannel.fulfilled]: (state, { payload }) => {
      state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
    },
    [actions.hideCategory.fulfilled]: (state, { payload }) => {
      state.selectedPlaylist.channelsKeys = keyBy(payload.channels, 'uuid');
      state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
    }
  },
});

export const playlists = {
  reducer: slice.reducer,
  actions: {
    ...slice.actions,
    ...actions,
  },
  selectors: {
    playlists: createSelector( // please don't use it. READ_ONLY
      state => state.playlists.playlists,
      playlists => playlists,
    ),
    keys: createSelector(
      state => state.playlists.playlistsKeys,
      keys => [...keys].reverse(),
    ),
    makePlaylistSelector: () => createSelector(
      (state, uuid) => state.playlists.playlists[uuid],
      playlist => playlist,
    ),
    favorites: createSelector(
      state => state.playlists.favorites,
      favorites => toArray(favorites),
    ),
    hasHiddenChannels: createSelector(
      state => state.playlists.selectedPlaylist.channelsKeys,
      channels => toArray(channels).find(({ hidden }) => hidden ),
    ),
    channelsKeys: createSelector(
      state => state.playlists.selectedPlaylist.channelsKeys,
      keys => toArray(keys),
    ),
    makeChannelSelector: () => createSelector(
      (state, uuid) => state.playlists.selectedPlaylist.channels[uuid],
      channel => channel,
    ),
    playlistCategories: createSelector(
      state => state.playlists.selectedPlaylist.categories,
      categories => categories,
    ),
    errorStatus: createSelector(
      state => state.playlists.error.status,
      errorStatus => errorStatus,
    ),
    errorKey: createSelector(
      state => state.playlists.error.errorKey,
      errorKey => errorKey,
    ),
    isLoading: createSelector(
      state => state.playlists.isLoading,
      isLoading => isLoading,
    ),
  }
};
