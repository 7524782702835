import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { account } from '../@redux/account';
import { useMemo } from 'react';

export const useI18n = (key, fallback) => {
  const settings = useSelector(account.selectors.settings);
  const language = useSelector(account.selectors.language);

  return useMemo(() =>
      get(settings, ['i18n', `${key}.${language}`], fallback),
    [settings, language, key, fallback,])
}
