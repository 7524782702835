export const getCurrencySymbol = (code) => {
  switch (code) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'TRY':
      return '₺';
    case 'RUB':
      return '₽';
    case 'UAH':
      return '₴';
    case 'MDL':
      return 'L';
    default:
      return '$';
  }
}
