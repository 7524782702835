import React, { useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import { playlists } from '@redux/playlists';
import { account } from '@redux/account';
import { atoms } from '@recoil/atoms';
import { useDispatch } from 'utils/useDispatch';

import { PlaylistCardUI } from './PlaylistCardUI';

export const PlaylistCard = ({ uuid }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const toggleModal = useSetRecoilState(atoms.playlists.modal);
  const setFilter = useSetRecoilState(atoms.playlists.selectedFilter);
  const setEditedPlaylistUUID = useSetRecoilState(atoms.playlists.editedPlaylistUUID);
  const isActiveUser = useSelector(account.selectors.isActiveUser);

  const makePlaylistSelector = useMemo(playlists.selectors.makePlaylistSelector, []);
  const { name, icon, channels_count, isLoading, source_type } = useSelector(state => makePlaylistSelector(state, uuid ));

  const syncPlaylist = useDispatch(playlists.actions.mergePlaylist);
  const deletePlaylist = useDispatch(playlists.actions.deletePlaylist);

  const handleEditClick = useCallback(() => {
    toggleModal(true);
    setEditedPlaylistUUID(uuid);
  }, [uuid, toggleModal, setEditedPlaylistUUID]);

  const handleSyncClick = useCallback(() => {
    syncPlaylist({ uuid });
  }, [uuid, syncPlaylist]);

  const handleDeleteClick = useCallback(() => {
    deletePlaylist({ uuid });
  }, [uuid, deletePlaylist]);

  const handleOnClick = useCallback(() => {
    navigate(`/app/playlists/${uuid}`);
    setFilter(null);
  }, [uuid, navigate, setFilter]);

  const channelsCount = useMemo(() => channels_count ? channels_count : t('playlists.null'), [t, channels_count]);
  const subtitle = useMemo(() =>  `${channelsCount} ${t('playlists.channels')}`,[t, channelsCount]);

  return (
    <PlaylistCardUI
      onClick={handleOnClick}
      title={name}
      subtitle={subtitle}
      sourceType={source_type}
      imgSrc={icon}
      isLoading={isLoading}
      isActive={params['*'] === uuid}
      hideActions={!isActiveUser}
      handleEditClick={handleEditClick}
      handleSyncClick={handleSyncClick}
      handleDeleteClick={handleDeleteClick}
    />
  )
}
